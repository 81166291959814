import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import moment from "moment";

import { PianiServiziListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";
import ServiziSelector from "../../components/ServiziSelector";
import DatePicker from "react-datepicker";
import { encDecDate } from "../../utils";

const Programmazioni = () => {
  const [
    { pianiServizi, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(PianiServiziListState, {
    servizio: {
      field: "servizio",
      value: undefined
    },
    ripianificato: {
      field: "ripianificato"
      // value: undefined
    },
    data_inizio: {
      field: "data_inizio",
      encdec: encDecDate
    },

    data_fine: {
      field: "data_fine",
      encdec: encDecDate
    }
  });

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="">
            <h4>Piani dei servizi</h4>
            <div className="d-flex">
              {/* <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label">Stato</label>

                <div className="col-sm-10">
                  <select
                    name="attivo"
                    value={filters.attivo}
                    onChange={onFilterChange}
                    className="form-control"
                  >
                    <option value="">Tutti</option>
                    <option value="1">Attivo</option>
                    <option value="0">Non attivo</option>
                  </select>
                </div>
              </div> */}

              <div className="form-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm filter-label">
                  Data inizio
                </label>
                <DatePicker
                  isClearable={true}
                  selectsStart
                  startDate={filters.data_inizio}
                  endDate={filters.data_fine}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.data_inizio}
                  onChange={(date, e) => {
                    return onFilterChange(date, "data_inizio");
                  }}
                />
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm filter-label">
                  Data fine
                </label>
                <DatePicker
                  isClearable={true}
                  selectsEnd
                  startDate={filters.data_inizio}
                  endDate={filters.data_fine}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.data_fine}
                  onChange={(date, e) => {
                    return onFilterChange(date, "data_fine");
                  }}
                />
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label-sm filter-label">
                  Tipo
                </label>

                <select
                  value={filters.tipo}
                  className="form-control col-sm-6"
                  onChange={v => {
                    return onFilterChange(v, "ripianificato");
                  }}
                >
                  <option value="">Tutti</option>
                  <option value="0">Pianificato</option>
                  <option value="1">Ripianificato (extra lavorazioni)</option>
                </select>
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label-sm filter-label">
                  Servizi
                </label>

                <ServiziSelector
                  selected={filters.servizio}
                  className="col-sm-10"
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "servizio");
                  }}
                ></ServiziSelector>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {pianiServizi ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Codice servizio</th>
                    <th>Servizio</th>
                    <th>Tipo</th>
                    <th>Inizio validità</th>
                    <th>Fine validità</th>
                    <th>Bozza</th>
                    <th>Dettagli</th>
                  </tr>
                </thead>
                <tbody>
                  {pianiServizi.map(pianoServizi => (
                    <tr key={pianoServizi.id}>
                      <td>{pianoServizi.servizio.codice_servizio}</td>
                      <td>{pianoServizi.servizio.descrizione}</td>
                      <td>
                        {pianoServizi.ripianificato
                          ? "Ripianificato"
                          : "Pianificato"}
                      </td>

                      <td>
                        {pianoServizi.inizio_val &&
                          moment(pianoServizi.inizio_val).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {pianoServizi.fine_val &&
                          moment(pianoServizi.fine_val).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {pianoServizi.bozza ? (
                          <span className="badge badge-primary">bozza</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <Link to={`/programmazioni/${pianoServizi.id}`}>
                          Visualizza
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Programmazioni;
