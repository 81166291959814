import React from "react";
import DateFormat from "../../components/DateFormat";
import PropertyList from "../../components/PropertyList";
import { useAuthUser } from "use-eazy-auth";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { format } from "date-fns";

const Profile = () => {
  const user = useAuthUser();

  return (
    user && (
      <Layout className="classname-applied-to-content">
        <Layout.Top>
          <Header></Header>
        </Layout.Top>
        <Layout.Left>
          <Menu></Menu>
        </Layout.Left>
        <Layout.Right></Layout.Right>

        <div className="p-3">
          <h4>Profilo utente</h4>
          <PropertyList
            propLists={[
              [
                ["Nome", `${user.user.first_name} ${user.user.last_name}`],
                [
                  "Ente",
                  user.user.operatore.gestore
                    ? user.user.operatore.gestore.codice
                    : "Comune di Milano"
                ],
                [
                  "Ultimo login",
                  <DateFormat
                    date={user.user.last_login}
                    format="DD/MM/YYYY HH:mm"
                  ></DateFormat>
                ]
              ],
              []
            ]}
          ></PropertyList>
        </div>
      </Layout>
    )
  );
};

export default Profile;
