import React from "react";

export default function Footer() {
  return (
    <nav className="navbar fixed-bottom navbar-light bg-light">
      <div className="text-center w-100">
        <span className="navbar-text">
          <small>Footer here - if needed</small>
        </span>
      </div>
    </nav>
  );
}
