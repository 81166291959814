import React, { useMemo } from "react";
import { useRunRj } from "react-rocketjump";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { TipoDatoListState } from "./localstate";

import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

const TipoDatoSelector = ({
  onChange,
  className,
  multiple,
  clearButton,
  currentFilter
}) => {
  const [{ tipiDato, pagination, loading }, { run: loadTipiDato }] = useRunRj(
    TipoDatoListState
  );

  const currentSelected = useMemo(() => {
    if (currentFilter && tipiDato) {
      const selectedIds = Array.isArray(currentFilter)
        ? currentFilter
        : [currentFilter];
      return tipiDato.filter(
        item => selectedIds.indexOf(item.id.toString()) !== -1
      );
    } else {
      return [];
    }
  }, [currentFilter, tipiDato]);

  return (
    <Typeahead
      bodyContainer
      paginate
      placeholder="Ricerca tipo dato"
      id={"TipoDatoSelector"}
      multiple={multiple}
      clearButton={clearButton}
      isLoading={loading}
      labelKey={option => `${option.nome}`}
      onInputChange={text => {
        loadTipiDato({ search: text });
      }}
      options={tipiDato || []}
      selected={currentSelected}
      onChange={onChange}
      className={className}
      size={"sm"}
    />
  );
};

export default TipoDatoSelector;
