import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import { elementiStradaliApi } from "../../api";

export const ElementiStradaliListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "elementiStradali",
    effectCaller: rj.configured(),
    effect: t => params => elementiStradaliApi(t, params),
    computed: {
      elementiStradali: "getList"
    }
  }
);
