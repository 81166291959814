import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import { schedeServiziApi } from "../../api";

export const SchedeServiziListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "schede",
    effectCaller: rj.configured(),
    effect: t => params => schedeServiziApi(t, params),
    computed: {
      schedeServizi: "getList"
    }
  }
);
