import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import { pianiServiziApi, pianoServiziApi, dettagliPDSApi } from "../../api";
import moment from "moment";

export const PianiServiziListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "pianiServizi",
    effectCaller: rj.configured(),
    effect: t => params => {
      const newParams = {
        ...params,
        data_inizio:
          params &&
          params.data_inizio &&
          moment(params.data_inizio).format("YYYY-MM-DD"),
        data_fine:
          params &&
          params.data_fine &&
          moment(params.data_fine).format("YYYY-MM-DD")
      };
      return pianiServiziApi(t, newParams);
    },
    computed: {
      pianiServizi: "getList"
    }
  }
);

export const PianoServiziState = rj({
  name: "pianoServizi",
  effectCaller: rj.configured(),
  effect: t => (id, query) => pianoServiziApi(t, id, query),
  computed: {
    pianoServizi: "getData"
  }
});

export const DettaglioPDSListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "dettagliPDS",
    effectCaller: rj.configured(),
    effect: t => (idPiano, params) => dettagliPDSApi(t, idPiano, params),
    computed: {
      dettagliPDS: "getList"
    }
  }
);
