import React from "react";
import { NavLink } from "react-router-dom";
import {
  MdHome,
  MdInsertChart,
  MdWork,
  MdAccessTime,
  MdStorage,
  MdMap,
  MdDashboard
} from "react-icons/md";

const MenuItem = ({ to, icon, children, exact = false }) => {
  return (
    <NavLink
      exact={exact}
      to={to}
      activeClassName="bg-selected text-white"
      className="list-group-item bg-primary text-white d-flex align-items-center justify-content-between"
    >
      {children}
      {icon}
    </NavLink>
  );
};

export default function Menu() {
  return (
    <div className="w-100 bg-primary text-white h-100">
      <ul className="list-group list-group-flush">
        {/* <MenuItem to="/" icon={<MdHome size="20" />} exact>
          Home
        </MenuItem> */}
        <MenuItem to="/" icon={<MdHome size="20" />} exact>
          Calendario
        </MenuItem>
        <MenuItem to="/catalogo-servizi" icon={<MdStorage size="20" />}>
          Catalogo servizi
        </MenuItem>

        <MenuItem to="/dati-base" icon={<MdDashboard size="20" />}>
          Dati di base
        </MenuItem>
        <MenuItem to="/classificazioni" icon={<MdDashboard size="20" />}>
          Classificazioni
        </MenuItem>
        <MenuItem to="/programmazioni" icon={<MdAccessTime size="20" />}>
          Piani dei servizi
        </MenuItem>
        <MenuItem to="/non-eseguito" icon={<MdWork size="20" />}>
          Non Eseguito
        </MenuItem>
        <MenuItem to="/navigatore-cartografico" icon={<MdMap size="20" />}>
          Navigatore cartografico
        </MenuItem>
        <MenuItem to="/reports" icon={<MdInsertChart size="20" />}>
          Reports
        </MenuItem>

        <MenuItem to="/documenti" icon={<MdInsertChart size="20" />}>
          Archivio documenti
        </MenuItem>
        <MenuItem to="/regole-consegna" icon={<MdInsertChart size="20" />}>
          Regole consegna
        </MenuItem>
        <MenuItem to="/documenti-periodo" icon={<MdInsertChart size="20" />}>
          Consegne documenti
        </MenuItem>
      </ul>
    </div>
  );
}
