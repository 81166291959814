import React from "react";
import { Alert } from "reactstrap";

export default function GenericFormError({
  // errors from Formik
  errors,
  // critical (only no internetl or 500) | all (all shit)
  level = "critical"
}) {
  const error = errors.__noFieldsServerError;

  if (error) {
    let msg = null;

    // Critical errors
    if (error.status >= 500) {
      // Server error
      msg = "A server error occured, please try again later.";
    } else if (error.status === undefined) {
      // No internet (i think)
      msg = "Please check yuor internet connection and try again.";
    }

    if (level === "all") {
      // Other errors
      if (error.status >= 400) {
        msg = "Client error";
      } else if (error) {
        msg = "Unknown error";
      }
      // TODO: Handle 400 not related 2 fields ES:. no_fields_errors of Django
    }

    if (msg) {
      return <Alert color="danger">{msg}</Alert>;
    }
  }

  return null;
}
