import React, { useCallback, useMemo } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ModalConfirm from "./ModalConfirm";
import PopoverConfirm from "./PopoverConfirm";
import PropertyList from "./PropertyList";

import { useRunRj, useRj } from "react-rocketjump";
import {
  DocumentoState,
  DocumentoPatchState,
  DocumentoDeleteState
} from "../localstates/documento";

export default function DocumentoModal({
  toggle,
  idDocumento,
  refresh,
  showEditCommands
}) {
  const [
    { documento, loading },
    { updateData: updateDoc }
  ] = useRunRj(DocumentoState, [idDocumento]);

  const { run: updateDocumento } = useRj(DocumentoPatchState)[1];
  const { run: deleteDocumento } = useRj(DocumentoDeleteState)[1];

  const confermaBozza = useCallback(
    (id, params) => {
      return updateDocumento.asPromise(id, params).then(resp => {
        updateDoc(resp);
        refresh();
      });
    },
    [refresh, updateDoc, updateDocumento]
  );

  const eliminaBozza = useCallback(
    (id, params) => {
      return deleteDocumento.asPromise(id).then(resp => {
        refresh();
        toggle();
      });
    },
    [deleteDocumento, refresh, toggle]
  );

  return documento ? (
    <>
      <ModalHeader toggle={toggle}>
        {documento.bozza ? "Bozza documento" : "Dettaglio documento"}
      </ModalHeader>
      <ModalBody>
        <PropertyList
          propLists={[
            [
              ["Tipo dato", documento.tipo_dato_data.nome],
              ["Titolo", documento.titolo],
              ["Inizio periodo", documento.periodo_inizio],
              ["Fine periodo", documento.periodo_fine],
              ["Note", documento.note],
              [
                "Contenuto",
                <a download href={documento.contenuto}>
                  Download
                </a>
              ],
              ["Data modifica", documento.data_modifica]
              // ["Codice scheda", schedaServizio.codice_scheda]
            ]
          ]}
        ></PropertyList>
      </ModalBody>
      <ModalFooter className="d-flex flex-row align-items-center justify-content-between">
        {showEditCommands && documento.bozza && (
          <>
            <PopoverConfirm
              title="Conferma eliminazione bozza"
              buttonClassName="btn btn-sm btn-danger"
              buttonText="Elimina bozza"
              okCallBack={() => eliminaBozza(documento.id)}
              body={
                "Confermi eliminazione bozza? Questa azione non è reversibile."
              }
            ></PopoverConfirm>
            <PopoverConfirm
              title="Conferma pubblicazione bozza"
              buttonClassName="btn btn-sm btn-success"
              buttonText="Pubblica bozza"
              okCallBack={() => confermaBozza(documento.id, { bozza: false })}
              body={
                "Confermi pubblicazione bozza? Questa azione non è reversibile."
              }
            ></PopoverConfirm>
          </>
        )}
      </ModalFooter>
    </>
  ) : null;
}
