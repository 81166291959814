import React from "react";

export default function PropertyList({ propLists = [] }) {
  return (
    <div className="row">
      {propLists.map((p, i) => (
        <div className="col-sm-6" key={i}>
          {p.map((prop, j) => (
            <div className="d-flex border-bottom border-white" key={j}>
              <div className="w-50 bg-grey p-2 text-right">{prop[0]}</div>
              <div className="w-50 p-2">{prop[1]}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
