import React from "react";
import Layout from "react-drawers";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import PropertyList from "../../components/PropertyList";
import { useRunRj, deps } from "react-rocketjump";
import { SchedaServizioState, ServizioState } from "./localstate";
import { Switch, Route, Link } from "react-router-dom";
import SchedaServizioDettaglio from "./SchedaServizioDettaglio";
import DettaglioServizio from "./DettaglioServizio";
import PianoServizio from "./PianoServizio";
import RipianificatoServizio from "./RipianificatoServizio";
import CaricamentoVNE from "./CaricamentoVNE";

export default function SchedaServizio({ match }) {
  const { params } = match;

  const [{ schedaServizio }] = useRunRj(SchedaServizioState, [params.id]);
  const [{ servizio }] = useRunRj(ServizioState, [
    deps.maybe(params.idServizio)
  ]);

  return (
    <>
      <Switch>
        <Route
          path="/catalogo-servizi/schede/:id"
          exact
          render={props => (
            <SchedaServizioDettaglio
              schedaServizio={schedaServizio}
              {...props}
            />
          )}
        />
        <Route
          path="/catalogo-servizi/schede/:id/servizio/:idServizio"
          exact
          render={props => (
            <DettaglioServizio
              schedaServizio={schedaServizio}
              servizio={servizio}
              {...props}
            />
          )}
        />
        <Route
          path="/catalogo-servizi/schede/:id/servizio/:idServizio/piano"
          exact
          render={props => (
            <PianoServizio
              schedaServizio={schedaServizio}
              servizio={servizio}
              {...props}
            />
          )}
        />
        <Route
          path="/catalogo-servizi/schede/:id/servizio/:idServizio/vne"
          exact
          render={props => (
            <CaricamentoVNE
              schedaServizio={schedaServizio}
              servizio={servizio}
              {...props}
            />
          )}
        />
        <Route
          path="/catalogo-servizi/schede/:id/servizio/:idServizio/extralavorazioni"
          exact
          render={props => (
            <RipianificatoServizio
              schedaServizio={schedaServizio}
              servizio={servizio}
              {...props}
            />
          )}
        />
      </Switch>
      )
    </>
  );
}
