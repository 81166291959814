import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import { nonEseguitoListApi, nonEseguitoApi } from "../../api";
import moment from "moment";

export const NonEseguitoListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "nonEseguitoList",
    effectCaller: rj.configured(),
    effect: t => params => {
      const newParams = {
        ...params,
        bozza: false,
        data_inizio:
          params &&
          params.data_inizio &&
          moment(params.data_inizio).format("YYYY-MM-DD"),
        data_fine:
          params &&
          params.data_fine &&
          moment(params.data_fine).format("YYYY-MM-DD")
      };
      return nonEseguitoListApi(t, newParams);
    },
    computed: {
      nonEseguitoList: "getList"
    }
  }
);

export const NonEseguitoState = rj({
  name: "nonEseguito",
  effectCaller: rj.configured(),
  effect: t => (id, query) => nonEseguitoApi(t, id, query),
  computed: {
    nonEseguito: "getData"
  }
});
