import { rj } from "react-rocketjump";
import { calendarioApi } from "../../api";

export const CalendarListState = rj({
  name: "calendario",
  effectCaller: rj.configured(),
  effect: t => (...args) => calendarioApi(t, ...args),
  computed: {
    events: "getData"
  }
});
