import React from "react";

export default function ClassificazioneLegend({ colors = {} }) {
  const classi = Object.keys(colors);

  return (
    <div className="d-flex flex-row">
      {classi.map(classe => (
        <div className="d-flex flex-row text-center mx-2 align-items-center justify-content-center">
          <div
            className="mr-1"
            style={{ width: 10, height: 10, background: colors[classe] }}
          ></div>{" "}
          {classe}
        </div>
      ))}
    </div>
  );
}
