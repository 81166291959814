import React, { useMemo, useRef, useCallback } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import {
  ClassificazioneDetailState,
  ClassificazioniOggettiGeoState
} from "./localstate";
import { MdSearch, MdZoomIn } from "react-icons/md";
import { Spinner } from "reactstrap";
import { useRunRj, useRj } from "react-rocketjump";
import PropertyList from "../../components/PropertyList";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";
import useRouterDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams";

import SimpleMap from "../../ol/SimpleMap";
import TileLayer from "../../ol/TileLayer";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import VectorLayer from "../../ol/VectorLayer";
import { Style, Fill, Stroke } from "ol/style";
import Popup from "../../ol/Popup";
import chunk from "lodash/chunk";
import { schemeTableau10 } from "d3-scale-chromatic";
import ClassificazioneLegend from "./ClassificazioneLegend";

function FeaturePopup() {
  return (
    <Popup
      autoPan
      renderHtml={(feature, close) => {
        const properties = feature.getProperties();
        const propNames = Object.keys(properties).filter(x => x !== "geometry");

        const l = Math.floor(propNames.length) / 2;
        const groups = chunk(propNames, l);
        const lists = groups.map(group =>
          group.map(item => [item, properties[item]])
        );
        return (
          <div className="p-3 bg-white" style={{ width: 500 }}>
            <PropertyList propLists={lists}></PropertyList>
            <hr />
            <button className="btn btn-sm" onClick={close}>
              close
            </button>
          </div>
        );
      }}
    ></Popup>
  );
}

const makeColors = classi => {
  let colors = {};
  classi.forEach((classe, i) => {
    colors[classe.nome] = schemeTableau10[i];
  });
  return colors;
};

const makeStyle = (classi, colors) => {
  const styleFun = function(feature) {
    const properties = feature.getProperties();
    const color = colors[properties.nome_classe];

    return new Style({
      stroke: new Stroke({
        color,
        width: 2
      })
    });
  };

  return styleFun;
};

const makeLoader = (source, runFunction, classificazione, nomeVia) => (
  extent,
  resolution,
  projection
) => {
  var proj = projection.getCode();
  const params = {
    // srsname: proj,
    classificazione: classificazione,
    nome_via: nomeVia,
    bbox: extent.join(",") + "," + proj
  };

  runFunction
    .onSuccess(resp => {
      const features = source.getFormat().readFeatures(resp);
      source.addFeatures(features);
    })
    .onFailure(() => {
      source.removeLoadedExtent(extent);
    })
    .run(params);
};

const ClassificazioneMap = ({ match }) => {
  const { params } = match;

  // const [nomeVia, setNomeVia] = useRouterQueryParam("nome_via", "");

  const [
    queryParams,
    setQueryParams,
    debParams,
    setDebParams
  ] = useRouterDebounceQueryParams({});

  const [{ classificazione }] = useRunRj(ClassificazioneDetailState, [
    params.id
  ]);

  const q = useMemo(() => {
    return { classificazione: params.id, nome_via: debParams.nomeVia };
  }, [params.id, debParams.nomeVia]);

  const legendColors = useMemo(() => {
    if (!classificazione) {
      return null;
    }
    return makeColors(classificazione.classi_oggetti_annotate);
  }, [classificazione]);

  const styleFunction = useMemo(() => {
    if (!classificazione) {
      return null;
    }
    return makeStyle(classificazione.classi_oggetti_annotate, legendColors);
  }, [classificazione, legendColors]);

  const [
    { oggettiGeo, loading },
    { run }
  ] = useRj(ClassificazioniOggettiGeoState, [q]);

  const mapRef = useRef();

  const source = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      url: `/api/classificazioni-oggetti/geo/?classificazione=${params.id}`,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857"
      }),
      strategy: bboxStrategy
    });
    const l = makeLoader(s, run, params.id, debParams.nomeVia);
    s.setLoader(l);
    return s;
  }, [params.id, run, debParams.nomeVia]);

  const zoomToFeature = useCallback(
    o => {
      const feat = source.getFeatureById(o.id);
      if (feat) {
        mapRef.current.olMap
          .getView()
          .fit(feat.getGeometry().getExtent(), { maxZoom: 18 });
      }
    },
    [source]
  );

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>
      <Layout.Content className="bg-white h-100 with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            <h4>
              <Link to="/classificazioni">Classificazioni</Link> /{" "}
              {classificazione?.nome}
            </h4>
          </div>

          <div className="d-flex justify-content-between">
            <div>
              {classificazione && (
                <div>
                  <Link
                    className="btn btn-sm btn-default"
                    to={`/classificazioni/${classificazione.id}/`}
                  >
                    Informazioni
                  </Link>{" "}
                  <Link
                    className="btn btn-sm btn-default"
                    to={`/classificazioni/${classificazione.id}/list`}
                  >
                    Elenco oggetti
                  </Link>{" "}
                  <Link
                    className="btn btn-sm btn-primary"
                    to={`/classificazioni/${classificazione.id}/map`}
                  >
                    Mappa oggetti
                  </Link>
                </div>
              )}
            </div>
            <div>
              {loading && <Spinner size="sm" color="primary"></Spinner>}
            </div>
          </div>
        </div>

        <div className="row no-gutters h-100">
          <div className="col-sm-9 h-100">
            <SimpleMap ref={mapRef}>
              <TileLayer source={new OSM()} />
              <VectorLayer style={styleFunction} source={source}>
                <FeaturePopup></FeaturePopup>
              </VectorLayer>
            </SimpleMap>
          </div>
          <div className="col-sm-3 p-2 h-100 overflow-auto">
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend d-flex flex-column justify-content-center">
                  <MdSearch size="18"></MdSearch>
                </div>
                <input
                  name="search"
                  placeholder="Cerca via"
                  value={queryParams.nomeVia}
                  onChange={e =>
                    // setNomeVia(e.target.value)
                    setDebParams({ nomeVia: e.target.value })
                  }
                  className="form-control form-control-sm"
                ></input>
              </div>
            </div>
            {debParams.nomeVia && oggettiGeo && (
              <table className="table table-striped table-sm">
                <tbody>
                  {oggettiGeo.features.slice(0, 100).map((oggetto, i) => {
                    return (
                      <tr key={i}>
                        <td>{oggetto.properties.nome_via}</td>
                        <td>{oggetto.properties.loc_ref}</td>
                        <td>
                          <MdZoomIn
                            onClick={() => {
                              zoomToFeature(oggetto);
                            }}
                          ></MdZoomIn>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="sticky-footer bg-light border-top">
          {classificazione && (
            <ClassificazioneLegend
              colors={legendColors}
            ></ClassificazioneLegend>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ClassificazioneMap;
