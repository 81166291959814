import React from "react";
import moment from "moment";

export default function DateFormat({
  date,
  parseFormat,
  format = "DD/MM/YYYY"
}) {
  if (!date) {
    return null;
  }
  const m = moment(date, parseFormat);
  return <span>{m.format(format)}</span>;
}
