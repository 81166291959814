import hoistNonReactStatics from "hoist-non-react-statics";
import React, { createContext, forwardRef } from "react";

const { Consumer, Provider } = createContext();

export const OlLayerConsumer = Consumer;
export const OlLayerProvider = Provider;

export const withOlLayer = WrappedComponent => {
  const WithOlLayerComponent = (props, ref) => (
    <Consumer>
      {olLayer => <WrappedComponent {...props} {...olLayer} ref={ref} />}
    </Consumer>
  );

  const name =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  WithOlLayerComponent.displayName = `withOlLayer(${name})`;

  const OlLayerComponent = forwardRef(WithOlLayerComponent);
  hoistNonReactStatics(OlLayerComponent, WrappedComponent);

  return OlLayerComponent;
};
