import hoistNonReactStatics from "hoist-non-react-statics";
import React, { createContext, forwardRef } from "react";

const { Consumer, Provider } = createContext();

export const OlConsumer = Consumer;
export const OlProvider = Provider;

export const withOlMap = WrappedComponent => {
  const WithOlMapComponent = (props, ref) => (
    <Consumer>
      {olMap => <WrappedComponent {...props} {...olMap} ref={ref} />}
    </Consumer>
  );

  const name =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  WithOlMapComponent.displayName = `withOlMap(${name})`;

  const OlMapComponent = forwardRef(WithOlMapComponent);
  hoistNonReactStatics(OlMapComponent, WrappedComponent);

  return OlMapComponent;
};
