import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import { regoleConsegnaApi, regolaConsegnaApi } from "../../api";
import moment from "moment";

export const RegoleConsegnaListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "regoleConsegna",
    effectCaller: rj.configured(),
    effect: t => params => {
      return regoleConsegnaApi(t, params);
    },
    computed: {
      regoleConsegna: "getList"
    }
  }
);

export const RegolaConsegnaStateState = rj({
  name: "regolaConsegna",
  effectCaller: rj.configured(),
  effect: t => (id, query) => regolaConsegnaApi(t, id, query),
  computed: {
    regolaConsegna: "getData"
  }
});
