import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import PropertyList from "../../components/PropertyList";
import {
  ClassificazioneDetailState,
  ClassificazioniOggettiListState
} from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";
import { useRunRj } from "react-rocketjump";

const Classificazione = ({ match }) => {
  const { params } = match;
  const [{ classificazione }] = useRunRj(ClassificazioneDetailState, [
    params.id
  ]);

  const [
    { classificazioniOggetti, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(ClassificazioniOggettiListState, {
    search: {
      field: "search",
      value: undefined
    },
    nome_via: {
      field: "nome_via",
      value: undefined
    },
    classificazione: {
      field: "classificazione",
      value: params.id
    }
  });

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            <h4>
              <Link to="/classificazioni">Classificazioni</Link> /{" "}
              {classificazione?.nome}
            </h4>
          </div>

          {classificazione && (
            <div className="d-flex justify-content-between">
              <div>
                <Link
                  className="btn btn-sm btn-default"
                  to={`/classificazioni/${classificazione.id}/`}
                >
                  Informazioni
                </Link>{" "}
                <Link
                  className="btn btn-sm btn-primary"
                  to={`/classificazioni/${classificazione.id}/list`}
                >
                  Elenco oggetti
                </Link>{" "}
                <Link
                  className="btn btn-sm btn-default"
                  to={`/classificazioni/${classificazione.id}/map`}
                >
                  Mappa oggetti
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="p-3">
          <div className="row">
            <div className="col-md-3 offset-md-6">
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend d-flex flex-column justify-content-center">
                    <MdSearch size="18"></MdSearch>
                  </div>
                  <input
                    name="search"
                    placeholder="Ricerca loc_ref o streetcode"
                    value={filters.search}
                    onChange={onFilterChange}
                    className="form-control form-control-sm"
                  ></input>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend d-flex flex-column justify-content-center">
                    <MdSearch size="18"></MdSearch>
                  </div>
                  <input
                    name="nome_via"
                    placeholder="Ricerca via"
                    value={filters.nome_via}
                    onChange={onFilterChange}
                    className="form-control form-control-sm"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {classificazioniOggetti ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Street code</th>
                    <th>Nome via</th>
                    <th>Loc_ref</th>
                    <th>Classe</th>
                  </tr>
                </thead>
                <tbody>
                  {classificazioniOggetti.map(classificazioneOggetto => (
                    <tr key={classificazioneOggetto.id}>
                      <td>{classificazioneOggetto.streetcode}</td>
                      <td>{classificazioneOggetto.nome_via}</td>
                      <td>{classificazioneOggetto.loc_ref}</td>
                      <td>{classificazioneOggetto.nome_classe}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Classificazione;
