import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ConfigureRj } from "react-rocketjump";
import EazyAuth from "use-eazy-auth";
import { meCall, loginCall } from "./auth";
import { AuthRoute, GuestRoute } from "use-eazy-auth/routes";
import Login from "./pages/Login";
import Home from "./pages/Home";
import CatalogoServizi from "./pages/CatalogoServizi";
import SchedaServizio from "./pages/CatalogoServizi/SchedaServizio";
import DatiBase from "./pages/DatiBase";
import Classificazioni from "./pages/Classificazioni";
import Classificazione from "./pages/Classificazioni/Classificazione";
import ClassificazioneList from "./pages/Classificazioni/ClassificazioneList";
import ClassificazioneMap from "./pages/Classificazioni/ClassificazioneMap";

import Vie from "./pages/Vie";
import ElementiStradali from "./pages/ElementiStradali";
import Giunzioni from "./pages/Giunzioni";
import Civici from "./pages/Civici";

import Programmazioni from "./pages/Programmazioni";
import DettaglioProgrammazioni from "./pages/Programmazioni/DettaglioProgrammazioni";

import NonEseguito from "./pages/NonEseguito";
import NavigatoreCartografico from "./pages/NavigatoreCartografico";
import Reports from "./pages/Reports";
import Report from "./pages/Reports/ReportDettaglio";

import Documenti from "./pages/Documenti";
import RegoleConsegna from "./pages/RegoleConsegna";
import DocumentiPeriodo from "./pages/DocumentiPeriodo";

import Calendar from "./pages/Calendar";

import Profile from "./pages/Profile";
import Layout from "react-drawers";

const Auth = ({ children }) => (
  <EazyAuth
    loginCall={loginCall}
    meCall={meCall}
    render={({ callAuthApiObservable }) => (
      <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
    )}
  />
);

function App() {
  return (
    <Auth>
      <Router>
        <Layout.Master
          top={80}
          left={240}
          right={300}
          bottom={54}
          mode="TLBR"
          rightOpenOnStart={false}
          leftCollapsedWidth={60}
        >
          <Switch>
            <GuestRoute path="/login" component={Login} />
            <AuthRoute path="/profile" exact component={Profile} />
            {/* <AuthRoute path="/" exact component={Home} /> */}
            <AuthRoute path="/" exact component={Calendar} />
            <AuthRoute
              path="/catalogo-servizi"
              exact
              component={CatalogoServizi}
            />
            <AuthRoute
              path={[
                "/catalogo-servizi/schede/:id",
                "/catalogo-servizi/schede/:id/servizio/:idServizio",
                "/catalogo-servizi/schede/:id/servizio/:idServizio/piano",
                "/catalogo-servizi/schede/:id/servizio/:idServizio/vne",
                "/catalogo-servizi/schede/:id/servizio/:idServizio/extralavorazioni"
              ]}
              exact
              component={SchedaServizio}
            />

            <AuthRoute path="/dati-base" exact component={DatiBase} />
            <AuthRoute path="/dati-base/vie/list" component={Vie} />
            <AuthRoute
              path="/dati-base/elementi-stradali/list"
              component={ElementiStradali}
            />
            <AuthRoute path="/dati-base/giunzioni/list" component={Giunzioni} />
            <AuthRoute path="/dati-base/civici/list" component={Civici} />

            <AuthRoute
              path="/classificazioni"
              component={Classificazioni}
              exact
            />
            <AuthRoute
              path="/classificazioni/:id"
              component={Classificazione}
              exact
            />
            <AuthRoute
              path="/classificazioni/:id/list"
              component={ClassificazioneList}
              exact
            />

            <AuthRoute
              path="/classificazioni/:id/map"
              component={ClassificazioneMap}
              exact
            />

            <AuthRoute path="/non-eseguito" component={NonEseguito} />
            <AuthRoute
              path="/programmazioni"
              exact
              component={Programmazioni}
            />
            <AuthRoute
              path="/programmazioni/:id"
              exact
              component={DettaglioProgrammazioni}
            />

            <AuthRoute
              path="/navigatore-cartografico"
              exact
              component={NavigatoreCartografico}
            />
            <AuthRoute path="/reports" component={Reports} exact />
            <AuthRoute path="/reports/:id" component={Report} exact />
            {/* <Route path='/privacy' exact component={Privacy} /> */}

            <AuthRoute path="/documenti" exact component={Documenti} />
            <AuthRoute
              path="/documenti-periodo"
              exact
              component={DocumentiPeriodo}
            />
            <AuthRoute
              path="/regole-consegna"
              exact
              component={RegoleConsegna}
            />
            <AuthRoute path="/calendario" component={Calendar} exact />
          </Switch>
        </Layout.Master>
      </Router>
    </Auth>
  );
}

export default App;
