import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useAuthState, useAuthActions, useAuthUser } from "use-eazy-auth";
import Layout from "react-drawers";
import { GoThreeBars } from "react-icons/go";

function Header({ history }) {
  const { logout } = useAuthActions();
  const { authenticated } = useAuthState();
  const { user } = useAuthUser();

  return (
    <nav className="navbar navbar-expand sticky-top navbar-dark bg-primary">
      <div className="">
        <span
          to="/"
          className="navbar-brand d-flex flex-row align-items-center"
        >
          <Layout.Switch left>
            {({ open, toggle }) => (
              <GoThreeBars
                onClick={toggle}
                className="text-white mr-2 pointer"
                size={24}
              />
            )}
          </Layout.Switch>
          GreenGO
        </span>
      </div>
      <ul className="navbar-nav mr-auto">
        {/* <li className="nav-item">
        <NavLink to='/privacy' className='nav-link'>Privacy</NavLink>
      </li> */}
      </ul>
      <ul className="navbar-nav ml-auto">
        {authenticated && (
          <>
            <li className="nav-item">
              <NavLink to="/profile" className="nav-link">
                {user.username}
                {!user.operatore?.gestore && (
                  <span className="ml-1 badge badge-dark badge-lg">
                    Comune di Milano
                  </span>
                )}
                {user.operatore?.gestore && (
                  <span className="ml-1 badge badge-warning badge-lg">
                    {" "}
                    {user.operatore?.gestore.codice}{" "}
                  </span>
                )}
              </NavLink>
            </li>
          </>
        )}

        {authenticated && (
          <li className="nav-item mr-3">
            <a
              onClick={e => {
                e.preventDefault();
                history.push("/");
                logout();
              }}
              className="nav-link"
              href="#logout"
            >
              Logout
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default withRouter(Header);
