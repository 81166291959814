import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import PropertyList from "../../components/PropertyList";
import { useRunRj, deps, useRj } from "react-rocketjump";
import { useAuthUser } from "use-eazy-auth";
import Layout from "react-drawers";
import Header from "../../components/Header";
import ModalConfirm from "../../components/ModalConfirm";
import Menu from "../../components/Menu";
import {
  CaricaVNEState,
  ModificaVNEState,
  ServizioNonEseguitoState
} from "./localstate";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";

import { Formik, Form, Field, FieldArray } from "formik";
import { FileField, DateField } from "../../components/Fields";
import {
  transformErrorForForm,
  fixInitialValuesNull
} from "../../components/Fields/utils";
import GenericFormError from "../../components/Fields/GenericFormError";

const getPDSConfirmText = (pianiBozza, modifichePianiAttivi) => {
  const pianiAttiviModificati = modifichePianiAttivi.filter(mod => {
    return mod.fine_val !== mod.fine_val_initial;
  });

  return (
    <div>
      <p>
        Proseguendo saranno apportate le seguenti modifiche al piano dei
        servizi:
      </p>
      <ul>
        {pianiBozza && pianiBozza.length > 0 && (
          <li>{pianiBozza.length} piani in bozza saranno attivati</li>
        )}
        {pianiAttiviModificati.map(modifica => (
          <li key={modifica.id}>
            Per il piano con id {modifica.id} verrà modificata la fine validità
            alla data {modifica.fine_val}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ModificaVNEForm = ({
  idServizio,
  nonEseguitoBozza,
  loadBozze,
  loadAttivi
}) => {
  const { run: save } = useRj(ModificaVNEState)[1];

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        save
          .onSuccess(resp => {
            actions.setSubmitting(false);
            loadAttivi();
            loadBozze();
          })
          .onFailure(error => {
            actions.setErrors(transformErrorForForm(error));
            actions.setSubmitting(false);
          })
          .run(idServizio);
      }}
    >
      {({ values, errors, isSubmitting, submitForm }) => (
        <Form>
          <GenericFormError errors={errors}></GenericFormError>

          <div>
            {isSubmitting && <Spinner></Spinner>}
            {!isSubmitting && (
              <ModalConfirm
                disabled={!nonEseguitoBozza || !nonEseguitoBozza.length}
                title="Conferma caricamento non eseguito"
                buttonClassName="btn btn-sm btn-danger"
                buttonText="Conferma caricamento non eseguito"
                okCallBack={submitForm}
                body={"Confermi il caricamento dei record in bozza?"}
              ></ModalConfirm>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const UploadForm = ({ idServizio, onClose, loadBozze }) => {
  const { run: upload } = useRj(CaricaVNEState)[1];

  return (
    <Formik
      initialValues={{ inizio_val: null, csv_file: null }}
      onSubmit={(values, actions) => {
        upload
          .onSuccess(resp => {
            onClose();
            loadBozze();
          })
          .onFailure(error => {
            actions.setErrors(transformErrorForForm(error));
            actions.setSubmitting(false);
          })
          .run(idServizio, values.csv_file);
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <ModalHeader className="flex-row justify-content-between bg-primary text-white">
            <span>Caricamento VNE</span>
          </ModalHeader>
          <ModalBody>
            <Field
              required
              component={FileField}
              name="csv_file"
              accept=".csv"
            ></Field>
            <GenericFormError errors={errors}></GenericFormError>
          </ModalBody>
          <ModalFooter>
            {isSubmitting && <Spinner></Spinner>}
            {!isSubmitting && (
              <button className="btn btn-primary" type="submit">
                Carica
              </button>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

const filtroNonEseguitoBozza = { bozza: 1 };
const filtroNonEseguitoAttivi = { bozza: 0 };

export default function DettaglioServizio({ schedaServizio, servizio, match }) {
  const user = useAuthUser();

  const [
    { nonEseguito: nonEseguitoBozza },
    { run: loadNonEseguitoBozza, deleteNonEseguitoBozza }
  ] = useRunRj(ServizioNonEseguitoState, [
    deps.maybeGet(servizio, "id"),
    filtroNonEseguitoBozza
  ]);

  const [
    { nonEseguito },
    { run: loadNonEseguito }
  ] = useRunRj(ServizioNonEseguitoState, [
    deps.maybeGet(servizio, "id"),
    filtroNonEseguitoAttivi
  ]);

  const loadBozze = useMemo(() => {
    if (!servizio) {
      return () => null;
    }
    return () => loadNonEseguitoBozza(servizio.id, filtroNonEseguitoBozza);
  }, [servizio, loadNonEseguitoBozza]);

  const loadAttivi = useMemo(() => {
    if (!servizio) {
      return () => null;
    }
    return () => loadNonEseguito(servizio.id, filtroNonEseguitoAttivi);
  }, [servizio, loadNonEseguito]);

  const [uploadPianoOpen, setUploadPianoOpen] = useState(false);
  const toggleUploadPianoOpen = useCallback(() => {
    return setUploadPianoOpen(!uploadPianoOpen);
  }, [uploadPianoOpen]);

  console.log("noneseguitoBozza", nonEseguitoBozza);

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        {schedaServizio && servizio && (
          <>
            <div className="sticky-header shadow-sm p-3 bg-white">
              <h4>
                {servizio && (
                  <span>
                    <Link to={`/catalogo-servizi/schede/${schedaServizio.id}`}>
                      {schedaServizio.titolo} - {schedaServizio.codice_scheda}
                    </Link>{" "}
                    /<span> {servizio.descrizione}</span>
                  </span>
                )}
              </h4>

              <div>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}
                >
                  Informazioni
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/piano`}
                >
                  Piano del servizio
                </Link>
                <Link
                  className="btn btn-sm btn-primary mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/vne`}
                >
                  Non eseguito
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/extralavorazioni`}
                >
                  Extra lavorazioni
                </Link>
              </div>
            </div>
            <div className="p-3">
              <div className="mt-2">
                <PropertyList
                  propLists={[
                    [
                      ["Codice servizio", servizio.codice_servizio],
                      ["Descrizione servizio", servizio.descrizione],
                      ["Codice scheda", schedaServizio.codice_scheda]
                    ],
                    [
                      ["Tipo servizio", schedaServizio.tipo_servizio],
                      ["Periodicità report", schedaServizio.periodicita_report],
                      ["Unità di misura", schedaServizio.um]
                    ]
                  ]}
                ></PropertyList>
              </div>
              <hr />

              {!user.user.is_operatore_comune && (
                <>
                  <div className="">
                    <h4>Non eseguito in bozza</h4>

                    {nonEseguitoBozza && nonEseguitoBozza.length === 0 && (
                      <p>Nessun record non eseguito in bozza</p>
                    )}

                    {nonEseguitoBozza && nonEseguitoBozza.length > 0 && (
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th>Quantità tot</th>
                            <th>Numero records</th>
                            <th>Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nonEseguitoBozza.map((d, i) => (
                            <tr key={i}>
                              <td>{d.data}</td>
                              <td>{d.quantita}</td>
                              <td>{d.numero_records}</td>
                              <td>
                                <ModalConfirm
                                  buttonClassName="btn btn-warning btn-sm"
                                  title="Elimina bozza piano"
                                  body={`Confermi l'eliminazione del piano in bozza?`}
                                  buttonText="Elimina data"
                                  okCallBack={() => {
                                    deleteNonEseguitoBozza(servizio.id, {
                                      data: d.data
                                    });
                                  }}
                                ></ModalConfirm>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    <div>
                      {nonEseguitoBozza && nonEseguitoBozza.length > 0 ? (
                        <ModalConfirm
                          title="Conferma caricamento non eseguito"
                          buttonClassName="btn btn-sm btn-warning"
                          buttonText="Elimina tutte le bozze"
                          okCallBack={() => deleteNonEseguitoBozza(servizio.id)}
                          body={
                            "Confermi eliminazione dei record di non lavorato in bozza?"
                          }
                        ></ModalConfirm>
                      ) : (
                        <div></div>
                      )}
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={toggleUploadPianoOpen}
                      >
                        Carica bozza
                      </button>
                    </div>
                  </div>

                  <hr />
                </>
              )}

              {nonEseguitoBozza && nonEseguitoBozza.length > 0 && (
                <>
                  <ModificaVNEForm
                    idServizio={servizio.id}
                    nonEseguitoBozza={nonEseguitoBozza}
                    loadAttivi={loadAttivi}
                    loadBozze={loadBozze}
                  ></ModificaVNEForm>

                  <hr />
                </>
              )}

              {servizio && (
                <div className="">
                  <h5>Non eseguito consolidato</h5>
                  {nonEseguito && nonEseguito.length === 0 && (
                    <p>Nessun record non eseguito</p>
                  )}

                  {nonEseguito && nonEseguito.length > 0 && (
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Quantità tot</th>
                          <th>Numero records</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nonEseguito.map((d, i) => (
                          <tr key={i}>
                            <td>{d.data}</td>
                            <td>{d.quantita}</td>
                            <td>{d.numero_records}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {servizio && (
          <Modal isOpen={uploadPianoOpen} toggle={toggleUploadPianoOpen}>
            <UploadForm
              loadBozze={loadBozze}
              idServizio={servizio.id}
              onClose={toggleUploadPianoOpen}
            ></UploadForm>
          </Modal>
        )}
      </Layout.Content>
    </Layout>
  );
}
