import React, { useMemo } from "react";
import { useRunRj } from "react-rocketjump";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { ServiziListState } from "./localstate";
import find from "lodash/find";

import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

const ServiziSelector = ({ onChange, className, selected, style }) => {
  const [{ servizi, pagination, loading }, { run: loadServizi }] = useRunRj(
    ServiziListState
  );

  const selectedArray = useMemo(() => {
    if (selected === undefined || !servizi) {
      return [];
    }
    const s = Array.isArray(selected) ? selected : [selected];
    return s.map(servizio => find(servizi, item => item.id === +servizio));
  }, [selected, servizi]);

  return (
    <Typeahead
      style={style}
      bodyContainer
      paginate
      placeholder="Ricerca servizi"
      id={"ServiziSelector"}
      multiple
      isLoading={loading}
      labelKey={option => `${option.descrizione}`}
      onInputChange={text => {
        loadServizi({ search: text });
      }}
      options={servizi || []}
      onChange={onChange}
      className={className}
      size={"sm"}
      selected={selectedArray}
    />
  );
};

export default ServiziSelector;
