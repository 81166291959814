import React, { Component, useMemo, useEffect } from "react";
import SimpleMap from "../ol/SimpleMap";
import TileLayer from "../ol/TileLayer";
import VectorLayer from "../ol/VectorLayer";
import VectorTileLayer from "../ol/VectorTileLayer";
import Popup from "../ol/Popup";
// import WebFont from 'webfontloader';
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { bbox as bboxStrategy } from "ol/loadingstrategy";

// import klBasicStyle from '../mapstyles/kl-basic.json';
import GeoJSON from "ol/format/GeoJSON";
import { Style, Fill, Stroke } from "ol/style";
import { interpolateWarm } from "d3-scale-chromatic";
import { scaleLinear } from "d3-scale";
import { rj, useRj } from "react-rocketjump";
import { programmazioniElementoStradaleApi, vieGeoApi } from "../api";
import qs from "querystring";

const exampleScale = scaleLinear()
  .domain([0, 1000])
  .range([0, 1]);

const exampleColorScale = scaleLinear()
  .domain([0, 1000])
  .range(["blue", "yellow", "red"]);

const testStyle = function(feature) {
  const properties = feature.getProperties();
  const color = "deepskyblue";

  return new Style({
    stroke: new Stroke({
      color,
      width: 2
    })
  });
};

const testStyleB = function(feature) {
  const properties = feature.getProperties();
  const color = interpolateWarm(exampleScale(properties.quantita_tot));
  // const color = exampleColorScale(properties.quantita_tot);

  return new Style({
    stroke: new Stroke({
      color,
      width: 3
    })
  });
};

// WebFont.load({
//   google: {
//     families: ['Noto Sans:300,400,700', 'sans-serif']
//   }
// });

const makeLoader = (source, runFunction, params = {}) => (
  extent,
  resolution,
  projection
) => {
  var proj = projection.getCode();
  const finalParams = {
    // srsname: proj,
    ...params,
    bbox: extent.join(",") + "," + proj
  };

  runFunction
    .onSuccess(resp => {
      console.log(resp);
      const features = source.getFormat().readFeatures(resp);
      source.addFeatures(features);
    })
    .onFailure(() => {
      source.removeLoadedExtent(extent);
    })
    .run(finalParams);
};

export const ClassificazioniOggettiGeoState = rj({
  name: "oggettiGeo",
  effectCaller: rj.configured(),
  effect: t => params => programmazioniElementoStradaleApi(t, params),
  computed: {
    oggettiGeo: "getData"
  }
});

export const VieGeoState = rj({
  name: "vieGeo",
  effectCaller: rj.configured(),
  effect: t => params => vieGeoApi(t, params),
  computed: {
    vieGeo: "getData"
  }
});

function Carto({ startDate, endDate, servizi = [], vie }) {
  const paramsVie = {
    data_inizio: startDate,
    data_fine: endDate,
    servizi
  };
  const params = {
    ...paramsVie,
    vie
  };
  const paramsEncoded = useMemo(() => qs.stringify(params), [params]);
  const paramsEncodedVie = useMemo(() => qs.stringify(paramsVie), [paramsVie]);

  const [{ oggetti }, { run }] = useRj(ClassificazioniOggettiGeoState, []);
  const [{ vieGeo }, { run: runVieGeo }] = useRj(VieGeoState, []);

  const source = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      //url: `/api/programmazioni-elemento-stradale/?${paramsEncoded}`,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857"
      }),
      strategy: bboxStrategy
    });
    return s;
  }, []);

  const sourceVie = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857"
      }),
      strategy: bboxStrategy
    });
    return s;
  }, []);

  useEffect(() => {
    const l = makeLoader(source, run, params);
    source.setLoader(l);
    source.refresh();
  }, [paramsEncoded]);

  useEffect(() => {
    const l = makeLoader(sourceVie, runVieGeo, paramsVie);
    sourceVie.setLoader(l);
    sourceVie.refresh();
  }, [paramsEncodedVie]);

  return (
    <div className="h-100 w-100">
      <SimpleMap>
        <TileLayer source={new OSM()} />

        {/* <VectorTileLayer
            style={testStyle}
            url={"/geo/dettaglio-elemento-stradale-mvt/{z}/{x}/{y}/"}
          >
            <Popup
              renderHtml={(feature, close) => {
                const properties = feature.getProperties();
                const propertyNames = Object.keys(properties);
                return (
                  <div className="bg-white p-2">
                    <table>
                      <tbody>
                        {propertyNames.map(property => (
                          <tr key={property}>
                            <td>{property}</td>
                            <td>{properties[property]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr></hr>
                    <button className="btn btn-sm" onClick={close}>
                      close
                    </button>
                  </div>
                );
              }}
            />
          </VectorTileLayer> */}
        <VectorLayer style={testStyle} source={sourceVie}>
          <Popup
            renderHtml={(feature, close) => {
              const properties = feature.getProperties();
              console.log(properties);
              return <div className="bg-white p-2">{properties.toponym}</div>;
            }}
          />
        </VectorLayer>

        <VectorLayer style={testStyleB} source={source}>
          <Popup
            renderHtml={(feature, close) => {
              const properties = feature.getProperties();
              const propertyNames = [
                "loc_ref",
                "streetcode",
                "nodo_da",
                "nodo_a",
                "quantita_tot",
                "lunghezza"
              ];
              return (
                <div className="bg-white p-2">
                  <table className="table table-sm">
                    <tbody>
                      {propertyNames.map(property => (
                        <tr key={property}>
                          <td>
                            <b>{property}</b>
                          </td>
                          <td>{properties[property]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <hr></hr>
                  <button className="btn btn-sm" onClick={close}>
                    close
                  </button>
                </div>
              );
            }}
          />
        </VectorLayer>
      </SimpleMap>
    </div>
  );
}

export default Carto;
