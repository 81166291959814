import React from "react";
import { withOlMap } from "./context/olMapContext";
import TileLayer from "ol/layer/Tile";

class TileLayerComponent extends React.Component {
  componentDidMount() {
    console.log(2, this.props);
    const { source } = this.props;
    this.layer = new TileLayer({
      source
    });
    this.props.olMap.addLayer(this.layer);
  }

  render() {
    return null;
  }
}

export default withOlMap(TileLayerComponent);
