import React, { useCallback } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import PropertyList from "../../components/PropertyList";
import { ClassificazioneDetailState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import { useRunRj } from "react-rocketjump";
import { saveAs } from "file-saver";

const Classificazione = ({ match }) => {
  const { params } = match;
  const [
    { classificazione },
    { exportCSV }
  ] = useRunRj(ClassificazioneDetailState, [params.id]);

  const downloadCSV = useCallback(
    estimate => {
      exportCSV
        // .onFailure(notifyError)
        .onSuccess(docBinary => {
          if (docBinary !== null) {
            saveAs(docBinary, `classificazione-${params.id}.csv`);
          }
        })
        .run(params.id);
    },
    [exportCSV]
  );

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            <h4>
              <Link to="/classificazioni">Classificazioni</Link> /{" "}
              {classificazione?.nome}
            </h4>
            <div className="form-group"></div>
          </div>

          {classificazione && (
            <div className="d-flex justify-content-between">
              <div>
                <Link
                  className="btn btn-sm btn-primary"
                  to={`/classificazioni/${classificazione.id}/`}
                >
                  Informazioni
                </Link>{" "}
                <Link
                  className="btn btn-sm btn-default"
                  to={`/classificazioni/${classificazione.id}/list`}
                >
                  Elenco oggetti
                </Link>{" "}
                <Link
                  className="btn btn-sm btn-default"
                  to={`/classificazioni/${classificazione.id}/map`}
                >
                  Mappa oggetti
                </Link>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-default"
                  onClick={downloadCSV}
                >
                  Scarica CSV
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="p-3">
          {classificazione && (
            <div className="mt-2">
              <PropertyList
                propLists={[
                  [
                    ["Nome", classificazione.nome],
                    ["Descrizione", classificazione.descrizione],
                    ["Riferimento", classificazione.riferimento]
                  ],
                  [
                    ["Tipo oggetto", classificazione.tipo_oggetto],
                    ["Oggetti totali", classificazione.numero_oggetti_totali],
                    [
                      "Oggetti classificati",
                      <span
                        className={`badge ${
                          classificazione.numero_oggetti_classificati ===
                          classificazione.numero_oggetti_totali
                            ? "badge-success"
                            : "badge-warning"
                        }`}
                      >
                        {classificazione.numero_oggetti_classificati}
                      </span>
                    ]
                  ]
                ]}
              ></PropertyList>

              <div className="mt-4">
                <h4>Classi di oggetti</h4>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Frequenza</th>
                      <th>Tempo recupero</th>
                      <th>Numero oggetti</th>
                    </tr>
                    {classificazione?.classi_oggetti_annotate?.map(classe => (
                      <tr>
                        <td>{classe.nome}</td>
                        <td>
                          {classe.frequenza_numero} / {classe.frequenza_periodo}
                        </td>
                        <td>{classe.tempo_recupero_sla_ore}</td>
                        <td>{classe.numero_oggetti}</td>
                      </tr>
                    ))}
                  </thead>
                </table>
              </div>
            </div>
          )}
        </div>

        <div className="sticky-footer bg-light border-top"></div>
      </Layout.Content>
    </Layout>
  );
};

export default Classificazione;
