import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import {
  gestoriApi,
  schedeServiziApi,
  schedaServizioApi,
  servizioApi,
  pianiServiziApi,
  reportsSchedaApi,
  caricaPDSApi,
  caricaRipianificatoApi,
  caricaVNEApi,
  modificaPDSApi,
  modificaRipianificatoApi,
  modificaVNEApi,
  pianiServizioApi,
  pianoServiziDeleteApi,
  nonEseguitoServizioApi,
  nonEseguitoServizioDeleteApi,
  nonEseguitoDeleteApi
} from "../../api";

export const GestoriListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "gestori",
    effectCaller: rj.configured(),
    effect: t => params => gestoriApi(t, params),
    computed: {
      gestori: "getList"
    }
  }
);

export const SchedeServiziListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "schedeServizi",
    effectCaller: rj.configured(),
    effect: t => params => schedeServiziApi(t, params),
    computed: {
      schedeServizi: "getList"
    }
  }
);

export const SchedaServizioState = rj({
  name: "schedaServizio",
  effectCaller: rj.configured(),
  effect: t => (id, query) => schedaServizioApi(t, id, query),
  computed: {
    schedaServizio: "getData"
  }
});

export const ServizioState = rj({
  name: "servizio",
  effectCaller: rj.configured(),
  effect: t => (id, query) => servizioApi(t, id, query),
  computed: {
    servizio: "getData"
  }
});

export const ServizioPianiServiziListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "servizioPianiServizi",
    effectCaller: rj.configured(),
    effect: t => (servizioId, params) =>
      pianiServiziApi(t, { servizio: servizioId, ...params }),
    computed: {
      pianiServizi: "getList"
    }
  }
);

export const CaricaPDSState = rj({
  name: "caricaPDS",
  effectCaller: rj.configured(),
  effect: t => (...args) => caricaPDSApi(t, ...args)
});

export const CaricaRipianificatoState = rj({
  name: "caricaPDS",
  effectCaller: rj.configured(),
  effect: t => (...args) => caricaRipianificatoApi(t, ...args)
});

export const ServizioReportCaricamentiListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "servizioReportCaricamenti",
    effectCaller: rj.configured(),
    effect: t => (servizioId, params) => {
      console.log(servizioId, params);
      return reportsSchedaApi(t, { servizio: servizioId, ...params });
    },
    computed: {
      caricamenti: "getList"
    }
  }
);

export const ServizioPianiState = rj({
  name: "servizioPiani",
  effectCaller: rj.configured(),
  effect: t => (servizioId, params) => {
    return pianiServizioApi(t, servizioId, params);
  },

  mutations: {
    deletePiano: rj.mutation.single({
      // takeEffect and reducer are injected for you
      effect: t => id =>
        pianoServiziDeleteApi(t, id)
          .toPromise()
          .then(() => ({
            id
          })),
      updater: (state, result) => ({
        ...state,
        data: state.data.filter(item => item.id !== result.id)
      })
    })
  },

  computed: {
    piani: "getData"
  }
});

export const ModificaPDSState = rj({
  name: "modificaPDS",
  effectCaller: rj.configured(),
  effect: t => (...args) => modificaPDSApi(t, ...args)
});

export const ModificaRipianificatoState = rj({
  name: "modificaRipianificato",
  effectCaller: rj.configured(),
  effect: t => (...args) => modificaRipianificatoApi(t, ...args)
});

export const ServizioNonEseguitoState = rj({
  name: "servizioNonEseguito",
  effectCaller: rj.configured(),
  effect: t => (servizioId, params) => {
    return nonEseguitoServizioApi(t, servizioId, params);
  },

  mutations: {
    deleteNonEseguitoBozza: rj.mutation.single({
      // takeEffect and reducer are injected for you
      effect: t => (id, bozza) =>
        nonEseguitoServizioDeleteApi(t, id, bozza)
          .toPromise()
          .then(() => ({
            bozza
          })),
      updater: (state, result) => ({
        ...state,
        data: []
      })
    }),
    delete: rj.mutation.single({
      // takeEffect and reducer are injected for you
      effect: t => id =>
        nonEseguitoDeleteApi(t, id)
          .toPromise()
          .then(() => ({
            id
          })),
      updater: (state, result) => ({
        ...state,
        data: state.data.filter(item => item.id !== result.id)
      })
    })
  },

  computed: {
    nonEseguito: "getData"
  }
});

export const CaricaVNEState = rj({
  name: "caricaVNE",
  effectCaller: rj.configured(),
  effect: t => (...args) => caricaVNEApi(t, ...args)
});

export const ModificaVNEState = rj({
  name: "modificaVNE",
  effectCaller: rj.configured(),
  effect: t => (...args) => modificaVNEApi(t, ...args)
  // computed: {
  //   // servizio: "getData"
  // }
});
