import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import PropertyList from "../../components/PropertyList";
import { useRunRj, useRj, deps } from "react-rocketjump";
import { useAuthUser } from "use-eazy-auth";
import Layout from "react-drawers";
import Header from "../../components/Header";
import ModalConfirm from "../../components/ModalConfirm";
import Menu from "../../components/Menu";
import get from "lodash/get";
import { ServizioPianiState, CaricaRipianificatoState } from "./localstate";
import { Modal } from "reactstrap";
import { useQsDataTable } from "react-use-datatable/react";
import Paginator from "../../components/Paginator";
import ModificaRipianificatoForm from "./ModificaRipianificatoForm";
import UploadForm from "./UploadForm";

const filtroPianiAttivi = { bozza: 0, ripianificato: 1 };
const filtroPianiBozza = { bozza: 1, ripianificato: 1 };

export default function RipianificatoServizio({
  schedaServizio,
  servizio,
  match
}) {
  const user = useAuthUser();

  const [
    { piani: pianiAttivi },
    { run: loadPianiAttivi }
  ] = useRunRj(ServizioPianiState, [
    deps.maybeGet(servizio, "id"),
    filtroPianiAttivi
  ]);
  const [
    { piani: pianiBozza },
    { run: loadPianiBozza, deletePiano }
  ] = useRunRj(ServizioPianiState, [
    deps.maybeGet(servizio, "id"),
    filtroPianiBozza
  ]);

  const loadBozze = useMemo(() => {
    if (!servizio) {
      return () => null;
    }
    return () => loadPianiBozza(servizio.id, filtroPianiBozza);
  }, [servizio, loadPianiBozza]);

  const [uploadPianoOpen, setUploadPianoOpen] = useState(false);
  const toggleUploadPianoOpen = useCallback(() => {
    return setUploadPianoOpen(!uploadPianoOpen);
  }, [uploadPianoOpen]);

  const { run: upload } = useRj(CaricaRipianificatoState)[1];

  const loadAttivi = useMemo(() => {
    if (!servizio) {
      return () => null;
    }
    return () => loadPianiAttivi(servizio.id, filtroPianiAttivi);
  }, [servizio, loadPianiAttivi]);

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        {schedaServizio && servizio && (
          <>
            <div className="sticky-header shadow-sm p-3 bg-white">
              <h4>
                {servizio && (
                  <span>
                    <Link to={`/catalogo-servizi/schede/${schedaServizio.id}`}>
                      {schedaServizio.titolo} - {schedaServizio.codice_scheda}
                    </Link>{" "}
                    /<span> {servizio.descrizione}</span>
                  </span>
                )}
              </h4>

              <div>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}
                >
                  Informazioni
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/piano`}
                >
                  Piano del servizio
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/vne`}
                >
                  Non eseguito
                </Link>
                <Link
                  className="btn btn-sm btn-primary mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/extralavorazioni`}
                >
                  Extra lavorazioni
                </Link>
              </div>
            </div>
            <div className="p-3">
              <div className="mt-2">
                <PropertyList
                  propLists={[
                    [
                      ["Codice servizio", servizio.codice_servizio],
                      ["Descrizione servizio", servizio.descrizione],
                      ["Codice scheda", schedaServizio.codice_scheda]
                    ],
                    [
                      ["Tipo servizio", schedaServizio.tipo_servizio],
                      ["Periodicità report", schedaServizio.periodicita_report],
                      ["Unità di misura", schedaServizio.um]
                    ]
                  ]}
                ></PropertyList>
              </div>
              <hr />

              {!user.user.is_operatore_comune && (
                <>
                  <div className="">
                    <h5>Extra lavorazioni in bozza</h5>

                    {pianiBozza && pianiBozza.length === 0 && (
                      <p>Nessuna extra lavorazione in bozza</p>
                    )}

                    {pianiBozza && pianiBozza.length > 0 && (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Tipo piano</th>
                            <th>Inizio val.</th>
                            <th>Fine val.</th>
                            <th>Quantità tot.</th>
                            <th>Numero vie</th>
                            <th>Numero oggetti</th>
                            <th>Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pianiBozza.map(piano => (
                            <tr key={piano.id}>
                              <td>{piano.id}</td>
                              <td>
                                {piano.ripianificato
                                  ? "Ripianificato"
                                  : "Pianificato"}
                              </td>
                              <td>{piano.inizio_val}</td>
                              <td>{piano.fine_val}</td>
                              <td>
                                {get(
                                  piano,
                                  "totale_lavorazioni_piano.quantita",
                                  ""
                                )}
                              </td>
                              <td>
                                {get(
                                  piano,
                                  "totale_lavorazioni_piano.numero_vie",
                                  ""
                                )}
                              </td>
                              <td>
                                {get(
                                  piano,
                                  "totale_lavorazioni_piano.numero_oggetti",
                                  ""
                                )}
                              </td>
                              <td>
                                <ModalConfirm
                                  buttonClassName="btn btn-warning btn-sm"
                                  title="Elimina bozza piano"
                                  body={`Confermi l'eliminazione del piano in bozza?`}
                                  buttonText="Elimina"
                                  okCallBack={() => {
                                    deletePiano(piano.id);
                                  }}
                                ></ModalConfirm>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={toggleUploadPianoOpen}
                      >
                        Carica bozza extralavorazioni
                      </button>
                    </div>
                  </div>

                  <hr />
                </>
              )}

              {servizio &&
                !user.user.is_operatore_comune &&
                pianiBozza &&
                pianiBozza.length > 0 && (
                  <>
                    <ModificaRipianificatoForm
                      idServizio={servizio.id}
                      pianiBozza={pianiBozza}
                      loadAttivi={loadAttivi}
                      loadBozze={loadBozze}
                    ></ModificaRipianificatoForm>

                    <hr />
                  </>
                )}

              {pianiAttivi ? (
                <div className="p-3">
                  <h4>Extra lavorazioni confermate</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Tipo piano</th>
                        <th>Inizio val.</th>
                        <th>Fine val.</th>
                        <th>Quantità tot.</th>
                        <th>Numero vie</th>
                        <th>Numero oggetti</th>
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pianiAttivi.map(piano => (
                        <tr key={piano.id}>
                          <td>{piano.id}</td>
                          <td>
                            {piano.ripianificato
                              ? "Ripianificato"
                              : "Pianificato"}
                          </td>
                          <td>{piano.inizio_val}</td>
                          <td>{piano.fine_val}</td>
                          <td>
                            {get(
                              piano,
                              "totale_lavorazioni_piano.quantita",
                              ""
                            )}
                          </td>
                          <td>
                            {get(
                              piano,
                              "totale_lavorazioni_piano.numero_vie",
                              ""
                            )}
                          </td>
                          <td>
                            {get(
                              piano,
                              "totale_lavorazioni_piano.numero_oggetti",
                              ""
                            )}
                          </td>
                          <td>
                            <Link to={`/programmazioni/${piano.id}`}>
                              Dettagli
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </>
        )}

        {servizio && (
          <Modal isOpen={uploadPianoOpen} toggle={toggleUploadPianoOpen}>
            <UploadForm
              loadBozze={loadBozze}
              idServizio={servizio.id}
              upload={upload}
              fineValRequired
              title="Caricamento extra lavorazioni"
              onClose={toggleUploadPianoOpen}
            ></UploadForm>
          </Modal>
        )}
      </Layout.Content>
    </Layout>
  );
}
