import React from "react";
import { withOlMap } from "./context/olMapContext";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import * as tilegrid from "ol/tilegrid";
import MVT from "ol/format/MVT";
import stylefunction from "ol-mapbox-style/stylefunction";
import { OlLayerProvider } from "./context/olLayerContext";

class VectorTileLayerComponent extends React.Component {
  state = {
    contextValue: null
  };

  componentDidMount() {
    const {
      url,
      attributions,
      format,
      tileGrid,
      tilePixelRatio,
      glStyle,
      glSource,
      style
    } = this.props;

    this.layer = new VectorTileLayer({
      source: new VectorTileSource({
        attributions,
        format,
        tileGrid,
        tilePixelRatio,
        url
      }),
      style
    });
    if (glStyle && glSource) {
      stylefunction(this.layer, glStyle, glSource);
    }

    this.props.olMap.addLayer(this.layer);
    const contextValue = {
      olLayer: this.layer
    };
    this.setState({ contextValue });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.contextValue && (
          <OlLayerProvider value={this.state.contextValue}>
            {this.props.children}
          </OlLayerProvider>
        )}
      </React.Fragment>
    );
  }
}

const VectorTileLayerComponentWithDefaults = withOlMap(
  VectorTileLayerComponent
);

VectorTileLayerComponentWithDefaults.defaultProps = {
  tileGrid: tilegrid.createXYZ({ tileSize: 512, maxZoom: 14 }),
  tilePixelRatio: 8,
  format: new MVT()
};

export default VectorTileLayerComponentWithDefaults;
