import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";

import { GiunzioniListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";

const Giunzioni = () => {
  const [
    { giunzioni, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(GiunzioniListState, {
    search: {
      field: "search",
      value: undefined
    }
  });

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            <h4 className>
              <Link to="/dati-base">Dati di base</Link> / Giunzioni
            </h4>
            <div className="form-group">
              <div className="input-group">
                <div class="input-group-prepend d-flex flex-column justify-content-center">
                  <MdSearch size="18"></MdSearch>
                </div>
                <input
                  name="search"
                  value={filters.search}
                  onChange={onFilterChange}
                  className="form-control form-control-sm"
                ></input>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {giunzioni ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Loc ref.</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {giunzioni.map(giunzione => (
                    <tr key={giunzione.loc_ref}>
                      <td>{giunzione.loc_ref}</td>
                      <td>{giunzione.id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Giunzioni;
