import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import {
  documentiApi,
  documentiPeriodoApi,
  documentoApi,
  documentoPatchApi,
  documentoDeleteApi,
  caricaDocumentoApi
} from "../api";
import moment from "moment";

export const DocumentiListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "documenti",
    effectCaller: rj.configured(),
    effect: t => params => {
      const newParams = {
        ...params,
        periodo_inizio:
          params &&
          params.periodo_inizio &&
          moment(params.periodo_inizio).format("YYYY-MM-DD"),
        periodo_fine:
          params &&
          params.periodo_fine &&
          moment(params.periodo_fine).format("YYYY-MM-DD")
      };
      return documentiApi(t, newParams);
    },
    computed: {
      documenti: "getList"
    }
  }
);

export const DocumentiPeriodoListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "documenti",
    effectCaller: rj.configured(),
    effect: t => params => {
      const newParams = {
        ...params,
        data_inizio:
          params &&
          params.data_inizio &&
          moment(params.data_inizio).format("YYYY-MM-DD"),
        data_fine:
          params &&
          params.data_fine &&
          moment(params.data_fine).format("YYYY-MM-DD")
      };
      return documentiPeriodoApi(t, newParams);
    },
    computed: {
      regoleConsegna: "getList"
    }
  }
);

export const CaricaDocumentoState = rj({
  name: "caricaDocumento",
  effectCaller: rj.configured(),
  effect: t => (...args) => caricaDocumentoApi(t, ...args)
  // computed: {
  //   // servizio: "getData"
  // }
});

export const DocumentoState = rj({
  name: "documento",
  effectCaller: rj.configured(),
  effect: t => (id, query) => documentoApi(t, id, query),
  computed: {
    documento: "getData",
    loading: "isPending"
  }
});

export const DocumentoPatchState = rj({
  name: "documentoPatch",
  effectCaller: rj.configured(),
  effect: t => (id, query) => documentoPatchApi(t, id, query),
  computed: {
    documento: "getData",
    loading: "isPending"
  }
});

export const DocumentoDeleteState = rj({
  name: "documentoDelete",
  effectCaller: rj.configured(),
  effect: t => (id, query) => documentoDeleteApi(t, id, query),
  computed: {
    loading: "isPending"
  }
});
