import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import qs from "querystring";
import omitBy from "lodash/omitBy";

const makeListApi = url => {
  return function(t, query = {}) {
    const q = omitBy(query, x => x === undefined);
    const queryParams = qs.stringify(q);
    return ajax({
      url: `${url}?${queryParams}`,
      headers: {
        Authorization: `Token ${t}`
      }
    }).pipe(map(r => r.response));
  };
};

const makeDetailApi = (url, method = "GET", action = "") => {
  return function(t, id, query = {}) {
    const q = omitBy(query, x => x === undefined);
    const queryParams = qs.stringify(q);
    return ajax({
      url: `${url}${id}${action || "/"}?${queryParams}`,
      method,
      headers: {
        Authorization: `Token ${t}`
      }
    }).pipe(map(r => r.response));
  };
};

export const gestoriApi = makeListApi(`/api/gestori/`);
export const schedeServiziApi = makeListApi(`/api/schede-servizi/`);
export const pianiServiziApi = makeListApi(`/api/piani-servizi/`);

export const vieApi = makeListApi(`/api/vie/`);
export const elementiStradaliApi = makeListApi(`/api/elementi-stradali/`);
export const giunzioniApi = makeListApi(`/api/giunzioni/`);
export const civiciApi = makeListApi(`/api/civici/`);

export const pianoServiziApi = makeDetailApi(`/api/piani-servizi/`);
export const pianoServiziDeleteApi = makeDetailApi(
  `/api/piani-servizi/`,
  "DELETE"
);
export const schedaServizioApi = makeDetailApi(`/api/schede-servizi/`);

export const serviziApi = makeListApi(`/api/servizi/`);
export const servizioApi = makeDetailApi(`/api/servizi/`);

export const nonEseguitoListApi = makeListApi(`/api/non-eseguito/`);
export const nonEseguitoApi = makeListApi(`/api/non-eseguito/`);

export const nonEseguitoServizioDeleteApi = makeDetailApi(
  `/api/servizi/`,
  "DELETE",
  `/non_eseguito/`
);
export const nonEseguitoDeleteApi = makeDetailApi(
  `/api/non-eseguito/`,
  "DELETE"
);

export const reportsSchedaApi = makeListApi(`/api/report-scheda/`);
export const reportSchedaApi = makeDetailApi(`/api/report-scheda/`);

export const dettaglioReportsSchedaApi = makeListApi(
  `/api/dettaglio-report-scheda/`
);

export const documentiApi = makeListApi(`/api/documenti/`);

export const documentoApi = makeDetailApi(`/api/documenti/`);

export function documentoPatchApi(t, id, payload) {
  return ajax({
    url: `/api/documenti/${id}/`,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${t}`
    },
    body: payload
  }).pipe(map(r => r.response));
}
export const documentoDeleteApi = makeDetailApi(`/api/documenti/`, "DELETE");

export const tipiDatoApi = makeListApi(`/api/tipi-dato/`);
export const tipoDatoApi = makeDetailApi(`/api/tipoDato/`);

export const regoleConsegnaApi = makeListApi(`/api/regole-consegna/`);
export const regolaConsegnaApi = makeDetailApi(`/api/regole-consegna/`);
export const documentiPeriodoApi = makeListApi(
  `/api/regole-consegna/documenti-periodo/`
);

// export function gestoriApi(t) {
//   return ajax({
//     url: `/api/gestori/`,
//     headers: {
//       Authorization: `Bearer ${t}`
//     }
//   }).pipe(map(r => r.response));
// }

// export function schedeServiziApi(t, params) {
//   return ajax({
//     url: `/api/schede-servizi/`,
//     headers: {
//       Authorization: `Bearer ${t}`
//     }
//   }).pipe(map(r => r.response));
// }

export function dettagliPDSApi(t, idPiano, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/piani-servizi/${idPiano}/dettagli/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
}

export function caricaPDSApi(t, idServizio, inizioVal, fineVal, csvFile) {
  const body = new FormData();
  body.append("inizio_val", inizioVal);
  body.append("fine_val", fineVal);
  body.append("csv_file", csvFile);

  return ajax({
    url: `/api/servizi/${idServizio}/caricamento_pds/`,
    method: "POST",
    headers: {
      Authorization: `Token ${t}`
    },
    body
  }).pipe(map(r => r.response));
}

export function caricaRipianificatoApi(
  t,
  idServizio,
  inizioVal,
  fineVal,
  csvFile
) {
  const body = new FormData();
  body.append("inizio_val", inizioVal);
  body.append("fine_val", fineVal);
  body.append("csv_file", csvFile);

  return ajax({
    url: `/api/servizi/${idServizio}/caricamento_ripianificato/`,
    method: "POST",
    headers: {
      Authorization: `Token ${t}`
    },
    body
  }).pipe(map(r => r.response));
}

export function caricaVNEApi(t, idServizio, csvFile) {
  const body = new FormData();
  body.append("csv_file", csvFile);

  return ajax({
    url: `/api/servizi/${idServizio}/caricamento_vne/`,
    method: "POST",
    headers: {
      Authorization: `Token ${t}`
    },
    body
  }).pipe(map(r => r.response));
}

export function modificaPDSApi(t, idServizio, payload) {
  return ajax({
    url: `/api/servizi/${idServizio}/modifica_pds/`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${t}`
    },
    body: payload
  }).pipe(map(r => r.response));
}

export function modificaRipianificatoApi(t, idServizio, payload) {
  return ajax({
    url: `/api/servizi/${idServizio}/modifica_ripianificato/`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${t}`
    },
    body: payload
  }).pipe(map(r => r.response));
}

export function modificaVNEApi(t, idServizio, payload) {
  return ajax({
    url: `/api/servizi/${idServizio}/modifica_vne/`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${t}`
    },
    body: payload
  }).pipe(map(r => r.response));
}

export function pianiServizioApi(t, idServizio, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/servizi/${idServizio}/piani/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
}

export function nonEseguitoServizioApi(t, idServizio, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/servizi/${idServizio}/non_eseguito/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
}

export function caricaDocumentoApi(t, params = {}) {
  const body = new FormData();
  // body.append("csv_file", csvFile);
  Object.keys(params).map(param => {
    body.append(param, params[param]);
  });

  return ajax({
    url: `/api/documenti/`,
    method: "POST",
    headers: {
      Authorization: `Token ${t}`
    },
    body
  }).pipe(map(r => r.response));
}

export const classificazioniApi = makeListApi(`/api/classificazioni/`);
export const classificazioneApi = makeDetailApi(
  `/api/classificazioni/`,
  "GET",
  "/dettaglio/"
);

export const classificazioniOggettiApi = makeListApi(
  `/api/classificazioni-oggetti/`
);

export function classificazioneApiCsv(t, id) {
  return ajax({
    url: `/api/classificazioni/${id}/csv/`,
    headers: {
      Authorization: `Token ${t}`
    },
    responseType: "blob"
  }).pipe(map(r => r.response));
}

export function classificazioneGeoApi(t, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/classificazioni-oggetti/geo/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
}

export function programmazioniElementoStradaleApi(t, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/programmazioni-elemento-stradale/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
}

export function vieGeoApi(t, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/vie/geo/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(
    map(r => {
      console.log(r);
      return r.response;
    })
  );
}

export function calendarioApi(t, query) {
  const queryParams = qs.stringify(query);
  return ajax({
    url: `/api/calendario/?${queryParams}`,
    headers: {
      Authorization: `Token ${t}`
    }
  }).pipe(map(r => r.response));
}
