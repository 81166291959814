import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import { vieApi } from "../../api";

export const VieListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "servizi",
    effectCaller: rj.configured(),
    effect: t => params => vieApi(t, params),
    computed: {
      vie: "getList"
    }
  }
);
