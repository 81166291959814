import React from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import PropertyList from "../../components/PropertyList";
import Layout from "react-drawers";
import Header from "../../components/Header";
import Menu from "../../components/Menu";

function getClassificazioneLink(schedaServizio) {
  const classificazione = get(schedaServizio, "classificazione");
  if (!classificazione) {
    return "";
  }
  return (
    <Link to={`/classificazioni/${classificazione}`}>
      {schedaServizio.classificazione_nome}
    </Link>
  );
}

export default function SchedaServizioDettaglio({ schedaServizio }) {
  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="d-flex justify-content-between sticky-header shadow-sm p-3">
          {schedaServizio && (
            <h4>
              <span>
                {schedaServizio.titolo} - {schedaServizio.codice_scheda}
              </span>
            </h4>
          )}
        </div>
        {schedaServizio && (
          <div className="p-3">
            <div className="mt-2">
              <PropertyList
                propLists={[
                  [
                    ["Descrizione", schedaServizio.descrizione],
                    ["Codice scheda", schedaServizio.codice_scheda],
                    [
                      "Codice macroscheda Comune",
                      schedaServizio.codice_macroscheda_comune
                    ],
                    ["Tipo oggetto", schedaServizio.tipo_oggetto],
                    ["Inizio validità", schedaServizio.inizio_val]
                  ],
                  [
                    ["ID", schedaServizio.id],
                    ["Tipo servizio", schedaServizio.tipo_servizio],
                    ["Periodicità report", schedaServizio.periodicita_report],
                    ["Unità di misura", schedaServizio.um],
                    [
                      "Classificazione oggetti",
                      getClassificazioneLink(schedaServizio)
                    ]
                  ]
                ]}
              ></PropertyList>

              <h4 className="mt-3">Servizi</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Codice servizio</th>
                    <th>Descrizione</th>
                    <th>Inizio validità</th>
                    <th>Fine validità</th>
                    <th>Gestore</th>
                  </tr>
                </thead>
                <tbody>
                  {schedaServizio.servizi.map(servizio => (
                    <tr key={servizio.id}>
                      <td>
                        <Link
                          to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}
                        >
                          {servizio.codice_servizio}
                        </Link>
                      </td>

                      <td>{servizio.descrizione}</td>
                      <td>{servizio.inizio_val}</td>
                      <td>{servizio.fine_val}</td>
                      <td>{servizio.gestore_data.codice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
}
