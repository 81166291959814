import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import DateFormat from "../../components/DateFormat";
import get from "lodash/get";

import { ReportsListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";
import ServiziSelector from "../../components/ServiziSelector";
import SchedaSelector from "../../components/SchedaSelector";
import DatePicker from "react-datepicker";
import { encDecDate } from "../../utils";

const flt = {
  servizio__isnull: true
};

const Reports = () => {
  const [
    { reports, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(ReportsListState, {
    scheda: {
      field: "scheda",
      value: undefined
    },
    tipo: {
      field: "tipo"
    },
    inizio_val: {
      field: "inizio_val",
      encdec: encDecDate
    },

    fine_val: {
      field: "fine_val",
      encdec: encDecDate
    },
    servizio: {
      field: "servizio__isnull",
      value: true
    }
  });

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="">
            <h4>Reports</h4>
            <div className="d-flex">
              {/* <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label">Stato</label>

                <div className="col-sm-10">
                  <select
                    name="attivo"
                    value={filters.attivo}
                    onChange={onFilterChange}
                    className="form-control"
                  >
                    <option value="">Tutti</option>
                    <option value="1">Attivo</option>
                    <option value="0">Non attivo</option>
                  </select>
                </div>
              </div> */}

              <div className="form-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm filter-label">
                  Inizio val.
                </label>
                <DatePicker
                  isClearable={true}
                  selectsStart
                  startDate={filters.inizio_val}
                  endDate={filters.fine_val}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.inizio_val}
                  onChange={(date, e) => {
                    return onFilterChange(date, "inizio_val");
                  }}
                />
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm filter-label">
                  Fine val.
                </label>
                <DatePicker
                  isClearable={true}
                  selectsEnd
                  startDate={filters.inizio_val}
                  endDate={filters.fine_val}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.fine_val}
                  onChange={(date, e) => {
                    return onFilterChange(date, "fine_val");
                  }}
                />
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label-sm filter-label">
                  Tipo
                </label>

                <select
                  value={filters.tipo}
                  className="form-control col-sm-6"
                  onChange={v => {
                    return onFilterChange(v, "tipo");
                  }}
                >
                  <option></option>
                  <option value="n">Caricamento del non eseguito</option>
                  <option value="p">Caricamento PdS</option>
                  <option value="m">Monitoraggio</option>
                </select>
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label-sm filter-label">
                  Schede
                </label>

                <SchedaSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "scheda");
                  }}
                  currentFilter={filters.scheda}
                ></SchedaSelector>

                {/* <ServiziSelector
                  selected={filters.servizio || []}
                  // className="col-sm-10"
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "servizio");
                  }}
                ></ServiziSelector> */}
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {reports ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Scheda</th>
                    <th>Servizio</th>
                    <th>Tipo</th>
                    <th>Data caricamento</th>
                    <th>Inizio validità</th>
                    <th>Fine validità</th>
                    <th>Dettagli</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map(report => (
                    <tr key={report.id}>
                      <td>{report.id}</td>
                      <td>{get(report, "scheda_data.codice_scheda")}</td>
                      <td>{get(report, "servizio_data.codice_servizio")}</td>
                      <td>{report.tipo_display}</td>
                      <td>
                        <DateFormat
                          date={report.timestamp}
                          format="DD/MM/YYYY HH:mm"
                        ></DateFormat>
                      </td>
                      <td>
                        <DateFormat date={report.inizio_val}></DateFormat>
                      </td>
                      <td>
                        <DateFormat date={report.fine_val}></DateFormat>
                      </td>
                      <td>
                        <Link to={`/reports/${report.id}`}>Visualizza</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Reports;
