import React, { useState, useCallback } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export default function ModalConfirm({
  okCallBack = () => null,
  buttonClassName = `btn btn-primary`,
  okButtonClassName = `btn btn-sm btn-success`,
  okButtonText = "OK",
  cancelButtonClassName = `btn btn-sm btn-default`,
  cancelButtonText = "Cancel",
  buttonText = "Confirm",
  title = "",
  body = "",
  disabled = false
}) {
  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const toggleOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen]);

  return (
    <>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={open}
        type="button"
      >
        {buttonText}
      </button>
      <Modal isOpen={isOpen} toggle={toggleOpen}>
        <ModalHeader toggle={toggleOpen}>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <button className={okButtonClassName} onClick={close}>
            {cancelButtonText}
          </button>
          <button
            className={cancelButtonClassName}
            onClick={() => {
              okCallBack();
              close();
            }}
          >
            {okButtonText}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
