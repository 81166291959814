const vieEncDec = {
  encode: vie => {
    const encVia = via => `${via.streetcode};${via.toponym}`;
    return Array.isArray(vie) ? vie.map(encVia) : encVia(vie);
  },
  decode: vie => {
    if (!vie) {
      return [];
    }
    const decVie = s => {
      let [streetcode, toponym] = s.split(";");
      return { streetcode, toponym };
    };
    return Array.isArray(vie) ? vie.map(decVie) : decVie(vie);
  }
};

export default vieEncDec;
