import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import {
  classificazioniApi,
  classificazioneApi,
  classificazioneApiCsv,
  classificazioniOggettiApi,
  classificazioneGeoApi
} from "../../api";

export const ClassificazioniListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "elementiStradali",
    effectCaller: rj.configured(),
    effect: t => params => classificazioniApi(t, params),
    computed: {
      classificazioni: "getList"
    }
  }
);

export const ClassificazioneDetailState = rj({
  name: "pianoServizi",
  effectCaller: rj.configured(),
  effect: t => id => classificazioneApi(t, id),
  computed: {
    classificazione: "getData"
  },
  mutations: {
    exportCSV: rj.mutation.single({
      effect: t => id => classificazioneApiCsv(t, id),
      updater: s => s // Nothing to do
    })
  }
});

export const ClassificazioniOggettiListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "oggetti",
    effectCaller: rj.configured(),
    effect: t => params => classificazioniOggettiApi(t, params),
    computed: {
      classificazioniOggetti: "getList"
    }
  }
);

export const ClassificazioniOggettiGeoState = rj({
  name: "oggettiGeo",
  effectCaller: rj.configured(),
  effect: t => params => classificazioneGeoApi(t, params),
  computed: {
    oggettiGeo: "getData",
    loading: "isPending"
  }
});
