import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import logger from "react-rocketjump/logger";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";

registerLocale("it", it);

logger();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
