import React from "react";
import { withOlMap } from "./context/olMapContext";
import { withOlLayer } from "./context/olLayerContext";
import Overlay from "ol/Overlay";

class Popup extends React.Component {
  state = { currentFeature: null };

  componentDidMount() {
    const { olMap, olLayer, autoPan } = this.props;
    this.overlay = new Overlay({
      element: this.popupContainer,
      positioning: "bottom-center",
      offset: [0, -10],
      autoPan
    });
    olMap.addOverlay(this.overlay);
    this.overlay.getElement().addEventListener("click", () => {
      this.overlay.setPosition();
    });

    this.bindClick(olLayer);
  }

  render() {
    const { renderHtml } = this.props;
    const { currentFeature } = this.state;

    return (
      <div
        className="arrow_box popup-container"
        ref={node => (this.popupContainer = node)}
      >
        {renderHtml &&
          currentFeature &&
          renderHtml(currentFeature, () => {
            // this.overlay.setPosition()
            this.setState({ currentFeature: null });
          })}
      </div>
    );
  }

  componentDidUpdate(oldProps, oldState) {
    const { currentFeature } = this.state;
    if (currentFeature !== oldState.currentFeature) {
      if (!currentFeature) {
        this.overlay.setPosition();
      } else {
        this.overlay.setPosition(this.state.coordinate);
      }
    }
  }

  bindClick = olLayer => {
    this.props.olMap.on("click", e => {
      const features = e.target.getFeaturesAtPixel(e.pixel, {
        layerFilter: candidateLayer => candidateLayer === olLayer
      });

      if (features && features.length) {
        const currentFeature = features[0];
        this.setState({ currentFeature, coordinate: e.coordinate });
      }

      // e.target.forEachFeatureAtPixel(e.pixel, (feature) => {
      //   console.log("feature clicked", feature)
      //   // const properties = feature.getProperties();
      //   // console.log("x", properties)
      //
      //   console.log(1, feature)
      // },
    });
  };
}

export default withOlLayer(withOlMap(Popup));
