import React, { useCallback } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import PropertyList from "../../components/PropertyList";
import Paginator from "../../components/Paginator";
import DateFormat from "../../components/DateFormat";
import { ReportDetailState, DettaglioReportsListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import { useRunRj, deps } from "react-rocketjump";
import { saveAs } from "file-saver";

// const flt = { 'giorno__isnull' : true, 'classe_oggetti__isnull': true }
const flt = {};

const Report = ({ match }) => {
  const { params } = match;
  const [{ report }, _] = useRunRj(ReportDetailState, [params.id]);

  const [
    { dettagli, pagination, loading },
    { page, ...filters },
    { run: loadCaricamenti, onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(
    DettaglioReportsListState,
    {
      search: {
        field: "search",
        value: undefined
      }
    },
    [params.id, flt]
  );

  console.log("xx", report)

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            <h4>
              <Link to="/reports">Reports</Link> / {report?.id}
            </h4>
            <div className="form-group"></div>
          </div>
        </div>

        <div className="p-3">
          {report && (
            
            <div className="mt-2">
              <PropertyList
                propLists={[
                  [
                    ["Id", report.id],
                    ["Tipo", report.tipo_display],
                    ["Scheda", report.scheda_data?.codice_scheda],
                    ["Servizio", report.servizio_data?.codice_servizio]
                  ],
                  [
                    [
                      "Inizio validità",
                      <DateFormat date={report.inizio_val}></DateFormat>
                    ],
                    [
                      "Fine validità",
                      <DateFormat date={report.fine_val}></DateFormat>
                    ],
                    [
                      "Data caricamento",
                      <DateFormat
                        date={report.timestamp}
                        format="DD/MM/YYYY HH:mm"
                      ></DateFormat>
                    ]
                  ]
                ]}
              ></PropertyList>
            </div>
          )}

          {!!report && report.tipo === 'm' && dettagli && (
            <div className="mt-4">
              <h3>Dettagli</h3>
              <table className="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th>Giorno</th>
                    <th>Classe oggetti</th>
                    <th>Pianificato</th>
                    <th>Eseguito pianificato</th>
                    <th>Extra pianificato</th>
                    <th>Non eseguito</th>
                    <th>Recuperato SLA</th>
                    <th>Recuperato NON SLA</th>
                    <th>Standards</th>
                  </tr>
                </thead>
                {dettagli.length > 0 && (
                  <tbody>
                    {dettagli.map(dettaglio => (
                      <tr key={dettaglio.id}>
                        <td>{dettaglio.giorno}</td>
                        <td>{dettaglio.classe_oggetti_data?.nome}</td>
                        <td>{dettaglio.pianificato}</td>
                        <td>{dettaglio.eseguito_pianificato}</td>
                        <td>{dettaglio.extra_pianificato}</td>
                        <td>{dettaglio.non_eseguito}</td>
                        <td>{dettaglio.recuperato_sla}</td>
                        <td>{dettaglio.recuperato_non_sla}</td>
                        <td className="text-center">
                          {dettaglio.calcoli.map(calcolo => (
                            <div key={calcolo.id}>
                              <span>
                                ({calcolo.standard}) <b>{calcolo.valore}</b> /{" "}
                                {calcolo.soglia}
                              </span>{" "}
                              <span>
                                {calcolo.standard_raggiunto ? (
                                  <span className="badge badge-success">
                                    OK
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">KO</span>
                                )}
                              </span>
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}

          {!!report && report.tipo !== 'm' &&  <div className="mt-4">
              <h3>Dettagli</h3>
            </div>}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Report;
