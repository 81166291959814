import React, { useCallback, useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdCreate } from "react-icons/md";
import { DocumentiListState } from "../../localstates/documento";
import { useQsDataTable } from "react-use-datatable/react";
import DateFormat from "../../components/DateFormat";
import Paginator from "../../components/Paginator";
import DatePicker from "react-datepicker";
import TipoDatoSelector from "../../components/TipoDatoSelector";
import SchedaSelector from "../../components/SchedaSelector";
import { Modal } from "reactstrap";
import DocumentoModal from "../../components/DocumentoModal";
import { encDecDate } from "../../utils";

const Documenti = () => {
  const [
    { documenti, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage, refresh }
  ] = useQsDataTable(DocumentiListState, {
    tipo_dato: {
      field: "tipo_dato",
      value: undefined
    },
    scheda: {
      field: "scheda",
      value: undefined
    },
    periodo_inizio: {
      field: "periodo_inizio",
      encdec: encDecDate
    },
    periodo_fine: {
      field: "periodo_fine",
      encdec: encDecDate
    }
  });

  const [currentDoc, setCurrentDoc] = useState(null);
  const unsetCurrentDoc = useCallback(() => {
    return setCurrentDoc(null);
  }, []);

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-white p-3 shadow-sm">
          <div className="">
            <h4>Archivio documenti</h4>
            <div className="d-flex flex-row">
              <div className="row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm">
                  Data inizio
                </label>
                <div className="col-sm-8 py-2">
                  <DatePicker
                    isClearable={filters.periodo_inizio}
                    selectsStart
                    startDate={filters.periodo_inizio}
                    endDate={filters.periodo_fine}
                    className="form-control form-control-sm col-sm-9"
                    autoComplete="off"
                    showYearDropdown
                    locale={"it"}
                    showMonthDropdown
                    scrollableYearDropdown
                    // dropdownMode="select"
                    yearDropdownItemNumber={80}
                    dateFormat="dd/MM/yyyy"
                    selected={filters.periodo_inizio}
                    onChange={(date, e) => {
                      return onFilterChange(date, "periodo_inizio");
                    }}
                    portalId="data-inizio-portal"
                  />
                </div>
              </div>

              <div className="row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm">Data fine</label>
                <div className="col-sm-8  py-2">
                  <DatePicker
                    isClearable={filters.periodo_fine}
                    selectsEnd
                    startDate={filters.periodo_inizio}
                    endDate={filters.periodo_fine}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    showYearDropdown
                    locale={"it"}
                    showMonthDropdown
                    scrollableYearDropdown
                    // dropdownMode="select"
                    yearDropdownItemNumber={80}
                    dateFormat="dd/MM/yyyy"
                    selected={filters.periodo_fine}
                    onChange={(date, e) => {
                      return onFilterChange(date, "periodo_fine");
                    }}
                    portalId="data-fine-portal"
                  />
                </div>
              </div>

              <div className="row w-25 p-1">
                <label className="col-sm-3 col-form-label-sm">Tipo dato</label>
                <TipoDatoSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(
                      v.length ? v : undefined,
                      "tipo_dato"
                    );
                  }}
                  currentFilter={filters.tipo_dato}
                ></TipoDatoSelector>
              </div>

              <div className="row w-25 p-1">
                <label className="col-sm-3 col-form-label-sm">
                  Scheda servizi
                </label>
                <SchedaSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "scheda");
                  }}
                  currentFilter={filters.scheda}
                ></SchedaSelector>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {documenti ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Tipo dato</th>
                    <th>Scheda</th>
                    <th>Titolo</th>
                    <th>Periodo</th>
                    <th>Dettagli</th>
                  </tr>
                </thead>
                <tbody>
                  {documenti.map(documento => (
                    <tr key={documento.id}>
                      <td>{documento.tipo_dato_data.nome}</td>
                      <td>{documento.scheda_data?.nome}</td>
                      <td>
                        {documento.bozza && <b>Bozza </b>}
                        {documento.titolo}
                      </td>
                      <td>
                        <DateFormat
                          date={documento.periodo_inizio}
                          format="DD/MM/YYYY"
                        ></DateFormat>{" "}
                        -{" "}
                        <DateFormat
                          date={documento.periodo_fine}
                          format="DD/MM/YYYY"
                        ></DateFormat>
                      </td>
                      <td>
                        <button
                          className={`btn btn-sm ml-2 ${
                            documento.bozza ? "btn-warning" : "btn-primary "
                          }`}
                          onClick={() => {
                            setCurrentDoc(documento);
                          }}
                        >
                          <MdCreate size="14" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>

        {currentDoc && (
          <Modal size="lg" isOpen={!!currentDoc} toggle={unsetCurrentDoc}>
            <DocumentoModal
              toggle={unsetCurrentDoc}
              idDocumento={currentDoc.id}
              refresh={refresh}
            ></DocumentoModal>
          </Modal>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default Documenti;
