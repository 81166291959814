import { rj } from "react-rocketjump";
import rjList, {
  nextPreviousPaginationAdapter
} from "react-rocketjump/plugins/list";
import {
  reportsSchedaApi,
  reportSchedaApi,
  dettaglioReportsSchedaApi
} from "../../api";
import moment from "moment";

export const ReportsListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "reports",
    effectCaller: rj.configured(),
    effect: t => params => {
      const newParams = {
        ...params,
        inizio_val:
          params &&
          params.inizio_val &&
          moment(params.inizio_val).format("YYYY-MM-DD"),
        fine_val:
          params &&
          params.fine_val &&
          moment(params.fine_val).format("YYYY-MM-DD")
      };
      return reportsSchedaApi(t, newParams);
    },
    computed: {
      reports: "getList"
    }
  }
);

export const ReportDetailState = rj({
  name: "report",
  effectCaller: rj.configured(),
  effect: t => (id, query) => reportSchedaApi(t, id, query),
  computed: {
    report: "getData"
  }
});

export const DettaglioReportsListState = rj(
  rjList({
    pageSize: 50,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: "reports",
    effectCaller: rj.configured(),
    effect: t => (reportId, params = {}) => {
      const newParams = {
        ...params,
        report: reportId
      };
      return dettaglioReportsSchedaApi(t, newParams);
    },
    computed: {
      dettagli: "getList"
    }
  }
);
