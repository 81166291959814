import React, { useMemo, useEffect } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import moment from "moment";
import DateFormat from "../../components/DateFormat";
import { NonEseguitoListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";
import ServiziSelector from "../../components/ServiziSelector";
import DatePicker from "react-datepicker";
import { encDecDate } from "../../utils";
import VieSelector, { vieEncDec } from "../../components/VieSelector";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";

const bozzaFilter = {
  bozza: false
};

const NonEseguito = () => {
  const [vie, setVie] = useRouterQueryParam("vie", [], vieEncDec);
  const vie_param = useMemo(() => {
    if (!vie) {
      return "";
    }
    const vieArr = Array.isArray(vie) ? vie : [vie];
    return vieArr.map(x => x.streetcode).join(",");
  }, [vie]);

  const [
    { nonEseguitoList, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(NonEseguitoListState, {
    servizio: {
      field: "servizio",
      value: undefined
    },

    data_inizio: {
      field: "data_inizio",
      encdec: encDecDate
    },

    data_fine: {
      field: "data_fine",
      encdec: encDecDate
    },
    vie_search: {
      field: "vie_search",
      value: undefined
    }
  });

  useEffect(() => {
    onFilterChange(vie_param, "vie_search");
  }, [onFilterChange, vie_param]);

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="">
            <h4>Non eseguito</h4>
            <div className="d-flex">
              {/* <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label">Stato</label>

                <div className="col-sm-10">
                  <select
                    name="attivo"
                    value={filters.attivo}
                    onChange={onFilterChange}
                    className="form-control"
                  >
                    <option value="">Tutti</option>
                    <option value="1">Attivo</option>
                    <option value="0">Non attivo</option>
                  </select>
                </div>
              </div> */}

              <div className="form-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm filter-label">
                  Data inizio
                </label>
                <DatePicker
                  isClearable={true}
                  selectsStart
                  startDate={filters.data_inizio}
                  endDate={filters.data_fine}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.data_inizio}
                  onChange={(date, e) => {
                    return onFilterChange(date, "data_inizio");
                  }}
                />
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm filter-label">
                  Data fine
                </label>
                <DatePicker
                  isClearable={true}
                  selectsEnd
                  startDate={filters.data_inizio}
                  endDate={filters.data_fine}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.data_fine}
                  onChange={(date, e) => {
                    return onFilterChange(date, "data_fine");
                  }}
                />
              </div>

              <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label-sm filter-label">
                  Servizi
                </label>

                <ServiziSelector
                  selected={filters.servizio}
                  className="col-sm-10"
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "servizio");
                  }}
                ></ServiziSelector>
              </div>
              <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label-sm filter-label">
                  Vie
                </label>
                <div className="col-sm-10">
                  <VieSelector
                    selected={vie}
                    onChange={values => {
                      setVie(
                        values.map(v => ({
                          streetcode: v.streetcode,
                          toponym: v.toponym
                        }))
                      );
                    }}
                  ></VieSelector>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {nonEseguitoList ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Servizio</th>
                    <th>Data</th>
                    <th>Causale</th>
                    <th>Esecuzione</th>
                    <th>Minuti ritardo</th>
                    <th>Quantità</th>
                  </tr>
                </thead>
                <tbody>
                  {nonEseguitoList.map(nonEseguito => (
                    <tr key={nonEseguito.id}>
                      <td>{nonEseguito.id}</td>
                      <td>{nonEseguito.servizio_data?.codice_servizio}</td>
                      <td>
                        <DateFormat date={nonEseguito.data}></DateFormat>
                      </td>
                      <td>{nonEseguito.causale}</td>
                      <td>
                        <DateFormat
                          date={nonEseguito.dataora_esecuzione}
                          format="DD/MM/YYYY HH:mm"
                        ></DateFormat>
                      </td>
                      <td>{nonEseguito.minuti_ritardo}</td>
                      <td>{nonEseguito.quantita}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default NonEseguito;
