import React, { useMemo } from "react";
import { useRj } from "react-rocketjump";
import { ModificaRipianificatoState } from "./localstate";
import {
  transformErrorForForm,
  fixInitialValuesNull
} from "../../components/Fields/utils";
import { Formik, Form, Field, FieldArray } from "formik";
import ModalConfirm from "../../components/ModalConfirm";
import get from "lodash/get";
import { Link } from "react-router-dom";
import { DateField } from "../../components/Fields";
import DateFormat from "../../components/DateFormat";
import GenericFormError from "../../components/Fields/GenericFormError";
import { Spinner } from "reactstrap";

const getRipianificatoConfirmText = pianiBozza => {
  return (
    <div>
      <p>
        Proseguendo saranno apportate le seguenti modifiche al piano dei
        servizi:
      </p>
      <ul>
        {pianiBozza && pianiBozza.length > 0 && (
          <li>{pianiBozza.length} piani in bozza saranno attivati</li>
        )}
      </ul>
    </div>
  );
};

export default function ModificaPianoForm({
  idServizio,
  pianiBozza,
  loadAttivi,
  loadBozze
}) {
  const { run: save } = useRj(ModificaRipianificatoState)[1];

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        save
          .onSuccess(resp => {
            actions.setSubmitting(false);
            loadAttivi();
            loadBozze();
          })
          .onFailure(error => {
            actions.setErrors(transformErrorForForm(error));
            actions.setSubmitting(false);
          })
          .run(idServizio);
      }}
    >
      {({ values, errors, isSubmitting, submitForm }) => (
        <Form>
          <GenericFormError errors={errors}></GenericFormError>

          <div>
            {isSubmitting && <Spinner></Spinner>}
            {!isSubmitting && (
              <ModalConfirm
                disabled={!pianiBozza || !pianiBozza.length}
                title="Conferma modifica piano"
                buttonText="Conferma modifiche piano"
                buttonClassName="btn-sm btn btn-danger"
                okCallBack={submitForm}
                body={getRipianificatoConfirmText(pianiBozza)}
              ></ModalConfirm>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
