import React from "react";
import Map from "ol/Map";
import View from "ol/View";
import { get, transform } from "ol/proj";
import { OlProvider } from "./context/olMapContext";

class OlMap extends React.Component {
  state = {
    contextValue: null
  };

  componentDidMount() {
    const p4326 = get("EPSG:4326");
    const p3857 = get("EPSG:3857");
    const c = [9.19, 45.46];
    const c2 = transform(c, p4326, p3857);
    console.log("c2", c2);

    this.olMap = new Map({
      target: this.mapNode,
      view: new View({
        center: c2,
        zoom: 12
      })
    });

    const contextValue = {
      olMap: this.olMap,
      target: this.mapNode
    };
    this.setState({ contextValue });
  }

  render() {
    return (
      <div
        ref={node => (this.mapNode = node)}
        style={{ height: "100%", width: "100%" }}
      >
        {this.state.contextValue && (
          <OlProvider value={this.state.contextValue}>
            {this.props.children}
          </OlProvider>
        )}
      </div>
    );
  }
}

export default OlMap;
