import React, { useEffect, useMemo } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import DateFormat from "../../components/DateFormat";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import { useRunRj } from "react-rocketjump";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";
import VieSelector, { vieEncDec } from "../../components/VieSelector";
import { DettaglioPDSListState, PianoServiziState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";
import get from "lodash/get";

const DettaglioProgrammazioni = ({ match }) => {
  const { params } = match;

  const [{ pianoServizi }, actions] = useRunRj(PianoServiziState, [params.id]);

  const [
    { dettagliPDS, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(
    DettaglioPDSListState,
    {
      search: {
        field: "search",
        value: undefined
      },
      vie_search: {
        field: "vie_search",
        value: undefined
      }
    },
    [params.id]
  );

  const [vie, setVie] = useRouterQueryParam("vie", [], vieEncDec);
  const vie_param = useMemo(() => {
    if (!vie) {
      return "";
    }
    const vieArr = Array.isArray(vie) ? vie : [vie];
    return vieArr.map(x => x.streetcode).join(",");
  }, [vie]);

  useEffect(() => {
    onFilterChange(vie_param, "vie_search");
  }, [onFilterChange, vie_param]);

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            {pianoServizi && (
              <div style={{ flex: 1 }}>
                <h4>
                  <Link to={`/programmazioni`}>Piani dei servizi</Link> /{" "}
                  {pianoServizi.servizio.codice_servizio}{" "}
                  {pianoServizi.bozza ? (
                    <span className="badge badge-primary">bozza</span>
                  ) : (
                    ""
                  )}
                </h4>
                <div>
                  <h6>
                    Scheda:{" "}
                    <Link
                      to={`/catalogo-servizi/schede/${pianoServizi.servizio.scheda}`}
                    >
                      {pianoServizi.servizio.scheda_data.descrizione}
                    </Link>{" "}
                    - Servizio:{" "}
                    <Link
                      to={`/catalogo-servizi/schede/${pianoServizi.servizio.scheda}/servizio/${pianoServizi.servizio.id}`}
                    >
                      {pianoServizi.servizio.descrizione}
                    </Link>
                  </h6>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50">
                    <div>
                      Inizio validità:{" "}
                      <DateFormat
                        date={get(pianoServizi, "inizio_val")}
                      ></DateFormat>
                    </div>
                    <div>
                      Fine validità:{" "}
                      <DateFormat
                        date={get(pianoServizi, "fine_val")}
                      ></DateFormat>
                    </div>
                    <div>
                      Tipo:{" "}
                      {pianoServizi.ripianificato
                        ? "Ripianificato"
                        : "Pianificato"}
                    </div>
                  </div>

                  <div className="w-50">
                    <div>
                      Quantità totale:{" "}
                      {get(pianoServizi, "totale_lavorazioni_piano.quantita")}{" "}
                      {pianoServizi.servizio.scheda_data.um}
                    </div>
                    <div>
                      Numero vie:{" "}
                      {get(pianoServizi, "totale_lavorazioni_piano.numero_vie")}
                    </div>
                    <div>
                      Numero oggetti:{" "}
                      {get(
                        pianoServizi,
                        "totale_lavorazioni_piano.numero_oggetti"
                      )}{" "}
                      ({pianoServizi.servizio.scheda_data.tipo_oggetto})
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div style={{ width: 400 }}>
              <VieSelector
                selected={vie}
                className="col-sm-10"
                onChange={values => {
                  setVie(
                    values.map(v => ({
                      streetcode: v.streetcode,
                      toponym: v.toponym
                    }))
                  );
                }}
              ></VieSelector>
            </div>
            {/* <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend d-flex flex-column justify-content-center">
                  <MdSearch size="18"></MdSearch>
                </div>
                <input
                  name="search"
                  value={filters.search}
                  onChange={onFilterChange}
                  className="form-control form-control-sm"
                ></input>
              </div>
            </div> */}
          </div>
        </div>

        <div className="p-3">
          {dettagliPDS ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Codice Viario</th>
                    <th>Nome via</th>
                    <th>
                      Loc ref{" "}
                      {pianoServizi && (
                        <span>
                          ({pianoServizi.servizio.scheda_data.tipo_oggetto})
                        </span>
                      )}
                    </th>
                    <th>Data</th>
                    <th>Giorno</th>
                    <th>Ora inizio</th>
                    <th>Ora fine</th>
                    <th>Quantità</th>
                  </tr>
                </thead>
                <tbody>
                  {dettagliPDS.map(dettaglioPDS => (
                    <tr key={dettaglioPDS.id}>
                      <td>{dettaglioPDS.streetcode}</td>
                      <td>{dettaglioPDS.toponym}</td>
                      <td>{dettaglioPDS.loc_ref}</td>
                      <td>{dettaglioPDS.data}</td>
                      <td>{dettaglioPDS.tipo_giorno}</td>
                      <td>{dettaglioPDS.ora_inizio}</td>
                      <td>{dettaglioPDS.ora_fine}</td>
                      <td>{dettaglioPDS.quantita}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default DettaglioProgrammazioni;
