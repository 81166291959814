import React from "react";
import { CaricaPDSState } from "./localstate";
import { useRj } from "react-rocketjump";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import { transformErrorForForm } from "../../components/Fields/utils";
import { ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import GenericFormError from "../../components/Fields/GenericFormError";
import { FileField, DateField } from "../../components/Fields";

export default function UploadForm({
  idServizio,
  onClose,
  loadBozze,
  upload,
  fineValRequired = false,
  title = "Caricamento PDS"
}) {
  return (
    <Formik
      initialValues={{
        inizio_val: moment().format("YYYY-MM-DD"),
        csv_file: null
      }}
      onSubmit={(values, actions) => {
        upload
          .onSuccess(resp => {
            onClose();
            loadBozze();
          })
          .onFailure(error => {
            actions.setErrors(transformErrorForForm(error));
            actions.setSubmitting(false);
          })
          .run(idServizio, values.inizio_val, values.fine_val, values.csv_file);
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <ModalHeader className="flex-row justify-content-between bg-primary text-white">
            <span>{title}</span>
          </ModalHeader>
          <ModalBody>
            <Field
              label="Inizio validità"
              component={DateField}
              name="inizio_val"
              required
            ></Field>
            <Field
              label="Fine validità"
              component={DateField}
              name="fine_val"
              required={fineValRequired}
            ></Field>
            <Field
              required
              component={FileField}
              name="csv_file"
              accept=".csv"
            ></Field>
            <GenericFormError errors={errors}></GenericFormError>
          </ModalBody>
          <ModalFooter>
            {isSubmitting && <Spinner></Spinner>}
            {!isSubmitting && (
              <button className="btn btn-primary" type="submit">
                Carica
              </button>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}
