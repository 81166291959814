import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import moment from "moment";

import { RegoleConsegnaListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";
import Paginator from "../../components/Paginator";
import DatePicker from "react-datepicker";
import TipoDatoSelector from "../../components/TipoDatoSelector";
import SchedaSelector from "../../components/SchedaSelector";
import { encDecDate } from "../../utils";

const RegoleConsegna = () => {
  const [
    { regoleConsegna, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(RegoleConsegnaListState, {
    tipo_dato: {
      field: "tipo_dato",
      value: undefined
    },
    scheda: {
      field: "scheda",
      value: undefined
    }
    // data_inizio: {
    //   field: "data_inizio",
    //   encdec: encDecDate,
    //   value: moment()
    //     .startOf("week")
    //     .add(1, "days")
    //     .toDate()
    // },
    // data_fine: {
    //   field: "data_fine",
    //   encdec: encDecDate,
    //   value: moment()
    //     .startOf("week")
    //     .add(8, "days")
    //     .toDate()
    // }
  });

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="">
            <h4>Regole consegna documenti</h4>
            <div className="d-flex">
              {/* <div className="fom-group w-25 p-1">
                <label className="col-sm-4 col-form-label-sm">
                  Data inizio
                </label>
                <DatePicker
                  isClearable={filters.data_inizio}
                  selectsStart
                  startDate={filters.data_inizio}
                  endDate={filters.data_fine}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.data_inizio}
                  onChange={(date, e) => {
                    return onFilterChange(date, "data_inizio");
                  }}
                />
              </div> */}

              {/* <div className="fom-group row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm">Data fine</label>
                <DatePicker
                  isClearable={filters.data_fine}
                  selectsEnd
                  startDate={filters.data_inizio}
                  endDate={filters.data_fine}
                  className="form-control col-sm-8"
                  autoComplete="off"
                  showYearDropdown
                  locale={"it"}
                  showMonthDropdown
                  scrollableYearDropdown
                  // dropdownMode="select"
                  yearDropdownItemNumber={80}
                  dateFormat="dd-MM-yyyy"
                  selected={filters.data_fine}
                  onChange={(date, e) => {
                    return onFilterChange(date, "data_fine");
                  }}
                />
              </div> */}

              <div className="row w-50 p-1">
                <label className="col-sm-3 col-form-label-sm">Tipo dato</label>
                <TipoDatoSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(
                      v.length ? v : undefined,
                      "tipo_dato"
                    );
                  }}
                  currentFilter={filters.tipo_dato}
                ></TipoDatoSelector>
              </div>
              <div className="row w-50 p-1">
                <label className="col-sm-3 col-form-label-sm">
                  Scheda servizi
                </label>
                <SchedaSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "scheda");
                  }}
                  currentFilter={filters.scheda}
                ></SchedaSelector>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {regoleConsegna ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Tipo dato</th>
                    <th>Scheda</th>
                    <th>Periodicità</th>
                    <th>Soggetto</th>
                    {/* <th>Dettagli</th> */}
                  </tr>
                </thead>
                <tbody>
                  {regoleConsegna.map(regolaConsegna => (
                    <tr key={regolaConsegna.id}>
                      <td>{regolaConsegna.tipo_dato_data.nome}</td>
                      <td>{regolaConsegna.scheda_data?.nome}</td>
                      <td>
                        {regolaConsegna.frequenza_attesa_periodo}{" "}
                        {regolaConsegna.frequenza_attesa_numero && (
                          <span>
                            ({regolaConsegna.frequenza_attesa_numero})
                          </span>
                        )}
                      </td>
                      <td>{regolaConsegna.soggetto}</td>
                      {/* <td>
                        <Link to={`/regole-consegna/${regolaConsegna.id}`}>
                          Visualizza
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default RegoleConsegna;
