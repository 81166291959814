import React, { useState } from "react";
import { withOlMap } from "./context/olMapContext";
import VectorLayer from "ol/layer/Vector";
import { OlLayerProvider } from "./context/olLayerContext";

class VectorLayerComponent extends React.Component {
  state = {
    contextValue: null
  };

  componentDidMount() {
    console.log("VectorLayerComponent", this.props);
    const { source, format, style } = this.props;
    this.layer = new VectorLayer({
      source,
      format,
      style
    });
    this.props.olMap.addLayer(this.layer);
    const contextValue = {
      olLayer: this.layer
    };
    this.setState({ contextValue });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.source !== this.props.source) {
      this.layer.setSource(this.props.source);
    }

    if (oldProps.style !== this.props.style) {
      this.layer.setStyle(this.props.style);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.contextValue && (
          <OlLayerProvider value={this.state.contextValue}>
            {this.props.children}
          </OlLayerProvider>
        )}
      </React.Fragment>
    );
  }
}

export default withOlMap(VectorLayerComponent);
