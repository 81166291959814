import React, { useState, useCallback, useMemo } from "react";
import { Modal, Popover, PopoverHeader, PopoverBody } from "reactstrap";

export default function PopoverConfirm({
  okCallBack = () => null,
  buttonClassName = `btn btn-primary`,
  okButtonClassName = `btn btn-sm btn-success`,
  okButtonText = "OK",
  cancelButtonClassName = `btn btn-sm btn-default`,
  cancelButtonText = "Cancel",
  buttonText = "Confirm",
  title = "",
  body = "",
  disabled = false,
  placement = "bottom"
}) {
  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const toggleOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen]);

  const buttonId = useMemo(() => {
    return "popover-button-" + (Math.random() * 1000).toFixed(0);
  }, []);

  return (
    <>
      <button
        id={buttonId}
        disabled={disabled}
        className={buttonClassName}
        onClick={open}
        type="button"
      >
        {buttonText}
      </button>
      <Popover
        container={document.body}
        isOpen={isOpen}
        toggle={toggleOpen}
        placement={placement}
        target={`${buttonId}`}
      >
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
        <PopoverBody className="d-flex flex-row justify-content-between">
          <button className={okButtonClassName} onClick={close}>
            {cancelButtonText}
          </button>
          <button
            className={cancelButtonClassName}
            onClick={() => {
              okCallBack();
              close();
            }}
          >
            {okButtonText}
          </button>
        </PopoverBody>
      </Popover>
    </>
  );
}
