import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import Paginator from "../../components/Paginator";
import { Link } from "react-router-dom";

import { SchedeServiziListState } from "./localstate";
import { useQsDataTable } from "react-use-datatable/react";
import { MdSearch } from "react-icons/md";

const CatalogoServizi = () => {
  const [
    { schedeServizi, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(SchedeServiziListState, {
    search: {
      field: "search",
      value: undefined
    }
  });

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="d-flex justify-content-between sticky-header p-3 bg-light shadow-sm">
          <h4>Catalogo Servizi / Schede</h4>
          <div className="form-group mb-0 mt-0">
            <div className="input-group">
              <div className="input-group-prepend d-flex flex-column justify-content-center">
                <MdSearch size="18"></MdSearch>
              </div>
              <input
                name="search"
                value={filters.search}
                onChange={onFilterChange}
                className="form-control form-control-sm"
              ></input>
            </div>
          </div>
        </div>

        <div className="p-3">
          {schedeServizi ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Descrizione</th>
                    <th>Servizi</th>
                    <th>Codice scheda</th>
                    <th>Codice macroscheda Comune</th>
                  </tr>
                </thead>
                <tbody>
                  {schedeServizi.map(scheda => (
                    <tr key={scheda.id}>
                      <td>{scheda.id}</td>
                      <td>
                        <Link to={`/catalogo-servizi/schede/${scheda.id}`}>
                          {scheda.descrizione}
                        </Link>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            scheda.servizi.length
                              ? "badge-primary"
                              : "badge-secondary"
                          }`}
                        >
                          {scheda.servizi.length}
                        </span>
                      </td>
                      <td>{scheda.codice_scheda}</td>
                      <td>{scheda.codice_macroscheda_comune}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default CatalogoServizi;
