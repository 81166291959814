import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";
import { Link } from "react-router-dom";
import { MdList, MdMap } from "react-icons/md";

const DatiBase = () => {
  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <h4>Dati di base</h4>
        </div>

        <div className="p-3">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Layer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Elementi stradali</td>
                <td>
                  <Link
                    className="mr-2"
                    to={`/dati-base/elementi-stradali/list`}
                  >
                    <MdList size="24"></MdList>
                  </Link>
                  <Link to={`/dati-base/elementi-stradali/map`}>
                    <MdMap size="24"></MdMap>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>Giunzioni</td>
                <td>
                  <Link className="mr-2" to={`/dati-base/giunzioni/list`}>
                    <MdList size="24"></MdList>
                  </Link>
                  <Link to={`/dati-base/giunzioni/map`}>
                    <MdMap size="24"></MdMap>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>Vie</td>
                <td>
                  <Link className="mr-2" to={`/dati-base/vie/list`}>
                    <MdList size="24"></MdList>
                  </Link>
                  <Link to={`/dati-base/vie/map`}>
                    <MdMap size="24"></MdMap>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>Numeri civici</td>
                <td>
                  <Link className="mr-2" to={`/dati-base/civici/list`}>
                    <MdList size="24"></MdList>
                  </Link>
                  <Link to={`/dati-base/civici/map`}>
                    <MdMap size="24"></MdMap>
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default DatiBase;
