import React, { useState, useMemo } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Carto from "../../components/Carto";
import Layout from "react-drawers";
import moment from "moment";
import DatePicker from "react-datepicker";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";
import { PianiServiziListState } from "../Programmazioni/localstate";
import ServiziSelector from "../../components/ServiziSelector";
import { useRunRj } from "react-rocketjump";
import VieSelector, { vieEncDec } from "../../components/VieSelector";

const NavigatoreCartografico = () => {
  //const [startDate, setStartDate] = useState(moment().toDate())
  const [startDate, setStartDate] = useRouterQueryParam(
    "data_inizio",
    moment()
      .startOf("week")
      .add(1, "days")
      .toDate(),
    {
      encode: date => moment(date).format("YYYY-MM-DD"),
      decode: dateParam => moment(dateParam).toDate()
    }
  );

  const [endDate, setEndDate] = useRouterQueryParam(
    "data_fine",
    moment()
      .startOf("week")
      .add(8, "days")
      .toDate(),
    {
      encode: date => moment(date).format("YYYY-MM-DD"),
      decode: dateParam => moment(dateParam).toDate()
    }
  );

  const [vie, setVie] = useRouterQueryParam("vie", [], vieEncDec);
  const vie_param = useMemo(() => {
    if (!vie) {
      return "";
    }
    const vieArr = Array.isArray(vie) ? vie : [vie];
    return vieArr.map(x => x.streetcode).join(",");
  }, [vie]);

  const [servizi, setServizi] = useRouterQueryParam("servizi", [], {
    encode: servizi => servizi.join(","),
    decode: serviziParam => serviziParam.split(",")
  });

  const startDateParam = useMemo(() => {
    return moment(startDate).format("YYYY-MM-DD");
  }, [startDate]);

  const endDateParam = useMemo(() => {
    return moment(endDate).format("YYYY-MM-DD");
  }, [endDate]);

  const [pianoServizi, setPianoServizi] = useRouterQueryParam(
    "piano_servizi",
    ""
  );

  const [{ pianiServizi, pagination, loading }, actions] = useRunRj(
    PianiServiziListState
  );

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>
      <Layout.Content className="bg-white h-100">
        <div className="sticky-header bg-light p-3 shadow-sm">
          <div className="d-flex justify-content-between">
            <h4>Navigatore Cartografico</h4>
            <div className="d-flex">
              <div className="p-1" style={{ width: 150 }}>
                <DatePicker
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="form-control w-100 text-center"
                  selected={startDate}
                  dateFormat="dd-MM-yyyy"
                  onChange={date => setStartDate(date)}
                />
              </div>
              <div className="p-1" style={{ width: 150 }}>
                <DatePicker
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  className="form-control w-100 text-center"
                  selected={endDate}
                  dateFormat="dd-MM-yyyy"
                  onChange={date => setEndDate(date)}
                  popperPlacement="top-end"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters h-100">
          <div className="col-9 h-100">
            <Carto
              startDate={startDateParam}
              endDate={endDateParam}
              servizi={servizi}
              vie={vie_param}
            ></Carto>
          </div>
          <div className="col-3 h-100">
            <div className="pt-3 pl-3">
              <h5>Filtro servizi</h5>
            </div>
            <div className="mb-4">
              <ServiziSelector
                style={{ zIndex: 100000 }}
                selected={servizi}
                className="col-sm-10"
                onChange={values => {
                  const v = values.map(x => x.id);
                  console.log(v);
                  setServizi(v);
                  // return onFilterChange(v.length ? v : undefined, "servizio");
                }}
              ></ServiziSelector>
            </div>
            <div className="pt-3 pl-3">
              <h5>Filtro vie</h5>
            </div>
            <div className="">
              <VieSelector
                selected={vie}
                className="col-sm-10"
                onChange={values => {
                  setVie(
                    values.map(v => ({
                      streetcode: v.streetcode,
                      toponym: v.toponym
                    }))
                  );
                }}
              ></VieSelector>
            </div>

            {/* <div className="form-group">
              <label>Piano servizi</label>
              <select
                className="form-control"
                value={pianoServizi}
                onChange={e => {
                  setPianoServizi(e.target.value);
                }}
              >
                ><option></option>
                {(pianiServizi || []).map(piano => (
                  <option value={piano.id} key={piano.id}>
                    {piano.servizio.codice_servizio}
                  </option>
                ))}
              </select>
            </div> */}
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default NavigatoreCartografico;
