import React, { useMemo } from "react";
import { useRunRj } from "react-rocketjump";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { VieListState } from "./localstate";
import find from "lodash/find";

import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

const VieSelector = ({ onChange, className, selected, style }) => {
  const [{ vie, pagination, loading }, { run: loadVie }] = useRunRj(
    VieListState
  );

  const selectedArray = useMemo(() => {
    if (selected === undefined || !vie) {
      return [];
    }
    const s = Array.isArray(selected) ? selected : [selected];
    return s.filter(x => !!x);
  }, [selected, vie]);

  const vieOptions = useMemo(() => {
    let out = vie || [];
    return selectedArray.concat(out);
  }, [selectedArray, vie]);

  return (
    <Typeahead
      style={style}
      bodyContainer
      paginate
      multiple
      placeholder="Ricerca vie"
      id={"VieSelector"}
      isLoading={loading}
      labelKey={option => `${option.toponym}`}
      onInputChange={text => {
        loadVie({ search: text });
      }}
      options={vieOptions}
      onChange={onChange}
      className={className}
      size={"sm"}
      selected={selectedArray}
    />
  );
};

export default VieSelector;
