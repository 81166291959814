import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import PropertyList from "../../components/PropertyList";
import { useRunRj, deps, useRj } from "react-rocketjump";
import Layout from "react-drawers";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQsDataTable } from "react-use-datatable/react";
import {
  ServizioReportCaricamentiListState,
  CaricaPDSState
} from "./localstate";
import Paginator from "../../components/Paginator";

export default function DettaglioServizio({ schedaServizio, servizio, match }) {
  const { params } = match;
  const [
    { caricamenti, pagination, loading },
    { page, ...filters },
    { run: loadCaricamenti, onFilterChange, onFilterChangeDebounced, goToPage }
  ] = useQsDataTable(
    ServizioReportCaricamentiListState,
    {
      search: {
        field: "search",
        value: undefined
      }
    },
    [params.idServizio]
  );

  const [uploadPianoOpen, setUploadPianoOpen] = useState(false);
  const toggleUploadPianoOpen = useCallback(() => {
    return setUploadPianoOpen(!uploadPianoOpen);
  }, [uploadPianoOpen]);

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right className="bg-white h-100 border p-2"></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        {schedaServizio && servizio && (
          <>
            <div className="sticky-header shadow-sm p-3 bg-white">
              <h4>
                {servizio && (
                  <span>
                    <Link to={`/catalogo-servizi/schede/${schedaServizio.id}`}>
                      {schedaServizio.titolo} - {schedaServizio.codice_scheda}
                    </Link>{" "}
                    /<span> {servizio.descrizione}</span>
                  </span>
                )}
              </h4>

              <div>
                <Link
                  className="btn btn-sm btn-primary mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}
                >
                  Informazioni
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/piano`}
                >
                  Piano del servizio
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/vne`}
                >
                  Non eseguito
                </Link>
                <Link
                  className="btn btn-sm btn-default mr-1"
                  to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/extralavorazioni`}
                >
                  Extra lavorazioni
                </Link>
              </div>
            </div>
            <div className="p-3">
              <div className="mt-2">
                <PropertyList
                  propLists={[
                    [
                      ["Codice servizio", servizio.codice_servizio],
                      ["Descrizione servizio", servizio.descrizione],
                      ["Codice scheda", schedaServizio.codice_scheda]
                    ],
                    [
                      ["Tipo servizio", schedaServizio.tipo_servizio],
                      ["Periodicità report", schedaServizio.periodicita_report],
                      ["Unità di misura", schedaServizio.um]
                    ]
                  ]}
                ></PropertyList>
              </div>
            </div>
          </>
        )}

        {caricamenti ? (
          <div className="p-3">
            <h4>Reports</h4>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tipo</th>
                  <th>Data caricamento</th>
                  <th>Inizio validità</th>
                  <th>Fine validità</th>
                  <th>Dettagli</th>
                </tr>
              </thead>
              <tbody>
                {caricamenti.map(report => (
                  <tr key={report.id}>
                    <td>{report.id}</td>
                    <td>{report.tipo}</td>
                    <td>{report.timestamp}</td>
                    <td>{report.inizio_val}</td>
                    <td>{report.fine_val}</td>
                    <td>
                      <Link to={`/reports/${report.id}`}>Visualizza</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>
    </Layout>
  );
}
