import React, { useMemo } from "react";
import { useRj } from "react-rocketjump";
import { useAuthUser } from "use-eazy-auth";
import { ModificaPDSState } from "./localstate";
import {
  transformErrorForForm,
  fixInitialValuesNull
} from "../../components/Fields/utils";
import { Formik, Form, Field, FieldArray } from "formik";
import ModalConfirm from "../../components/ModalConfirm";
import get from "lodash/get";
import { Link } from "react-router-dom";
import { DateField } from "../../components/Fields";
import DateFormat from "../../components/DateFormat";
import GenericFormError from "../../components/Fields/GenericFormError";
import { Spinner } from "reactstrap";

const getPDSConfirmText = (pianiBozza, modifichePianiAttivi) => {
  const pianiAttiviModificati = modifichePianiAttivi.filter(mod => {
    return mod.fine_val !== mod.fine_val_initial;
  });

  return (
    <div>
      <p>
        Proseguendo saranno apportate le seguenti modifiche al piano dei
        servizi:
      </p>
      <ul>
        {pianiBozza && pianiBozza.length > 0 && (
          <li>{pianiBozza.length} piani in bozza saranno attivati</li>
        )}
        {pianiAttiviModificati.map(modifica => (
          <li key={modifica.id}>
            Per il piano con id {modifica.id} verrà modificata la fine validità
            alla data {modifica.fine_val}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function ModificaPianoForm({
  idServizio,
  pianiAttivi,
  pianiBozza,
  loadBozze,
  loadAttivi
}) {
  const user = useAuthUser();

  const { run: save } = useRj(ModificaPDSState)[1];

  const initialValues = useMemo(() => {
    if (!pianiAttivi) {
      return { piani_attivi: [] };
    }
    const out = {
      piani_attivi: pianiAttivi.map(item => ({
        id: item.id,
        fine_val: item.fine_val,
        fine_val_initial: item.fine_val,
        inizio_val: item.inizio_val,
        totale_lavorazioni_piano: item.totale_lavorazioni_piano,
        ripianificato: item.ripianificato
      }))
    };
    return fixInitialValuesNull(out);
  }, [pianiAttivi]);

  if (!pianiAttivi) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        save
          .onSuccess(resp => {
            actions.setSubmitting(false);
            loadAttivi();
            loadBozze();
          })
          .onFailure(error => {
            actions.setErrors(transformErrorForForm(error));
            actions.setSubmitting(false);
          })
          .run(idServizio, values);
      }}
    >
      {({ values, errors, isSubmitting, submitForm }) => (
        <Form>
          {pianiAttivi && pianiAttivi.length > 0 && (
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Tipo piano</th>
                  <th>Inizio val.</th>
                  <th>Fine val.</th>
                  <th>Modifica fine val.</th>
                  <td>Quantita tot.</td>
                  <td>Numero vie</td>
                  <td>Numero oggetti</td>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="piani_attivi"
                  render={arrayHelpers => (
                    <>
                      {values.piani_attivi.map((piano, index) => (
                        <tr key={piano.id}>
                          <td>
                            <Link to={`/programmazioni/${piano.id}`}>
                              {piano.id}
                            </Link>
                          </td>
                          <td>
                            {piano.ripianificato
                              ? "Ripianificato"
                              : "Pianificato"}
                          </td>

                          <td>
                            <DateFormat date={piano.inizio_val}></DateFormat>
                          </td>
                          <td>
                            <DateFormat
                              date={piano.fine_val_initial}
                            ></DateFormat>
                          </td>
                          <td>
                            <Field
                              className="form-control form-control-sm text"
                              placeHolder={"Inserire data fine"}
                              component={DateField}
                              name={`piani_attivi[${index}].fine_val`}
                            ></Field>
                          </td>
                          <td>
                            {get(
                              piano,
                              "totale_lavorazioni_piano.quantita",
                              ""
                            )}
                          </td>
                          <td>
                            {get(
                              piano,
                              "totale_lavorazioni_piano.numero_vie",
                              ""
                            )}
                          </td>
                          <td>
                            {get(
                              piano,
                              "totale_lavorazioni_piano.numero_oggetti",
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                />
              </tbody>
            </table>
          )}

          <GenericFormError errors={errors}></GenericFormError>

          {!user.user.is_operatore_comune && (
            <div>
              {isSubmitting && <Spinner></Spinner>}
              {!isSubmitting && (
                <ModalConfirm
                  disabled={
                    (!pianiBozza || !pianiBozza.length) &&
                    !values.piani_attivi.filter(x => x.fine_val).length
                  }
                  title="Conferma modifica piano"
                  buttonText="Conferma modifiche piano"
                  buttonClassName="btn-sm btn btn-danger"
                  okCallBack={submitForm}
                  body={getPDSConfirmText(pianiBozza, values.piani_attivi)}
                ></ModalConfirm>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
