import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Layout from "react-drawers";

const Home = () => {
  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <div className="p-3">
        <h4>Homepage</h4>
      </div>
    </Layout>
  );
};

export default Home;
