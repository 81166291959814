import React, { useState, useCallback } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import DateFormat from "../../components/DateFormat";
import Layout from "react-drawers";
import moment from "moment";

import { DocumentiPeriodoListState } from "../../localstates/documento";
import { useQsDataTable } from "react-use-datatable/react";
import Paginator from "../../components/Paginator";
import DatePicker from "react-datepicker";
import TipoDatoSelector from "../../components/TipoDatoSelector";
import SchedaSelector from "../../components/SchedaSelector";
import { Modal } from "reactstrap";
import DocumentiUploadForm from "./DocumentiUploadForm";
import DocumentoModal from "../../components/DocumentoModal";
import { MdCreate } from "react-icons/md";
import { encDecDate } from "../../utils";

const DocumentiPeriodo = () => {
  const [
    { regoleConsegna, pagination, loading },
    { page, ...filters },
    { onFilterChange, onFilterChangeDebounced, goToPage, refresh }
  ] = useQsDataTable(DocumentiPeriodoListState, {
    tipo_dato: {
      field: "tipo_dato",
      value: undefined
    },
    scheda: {
      field: "scheda",
      value: undefined
    },
    data_inizio: {
      field: "data_inizio",
      encdec: encDecDate,
      value: moment()
        .startOf("week")
        .toDate()
    },
    data_fine: {
      field: "data_fine",
      encdec: encDecDate,
      value: moment()
        .startOf("week")
        .add(7, "days")
        .toDate()
    }
  });

  const [currentPeriodoUpload, setCurrentPeriodoUpload] = useState(null);
  const unsetCurrentPeriodoUpload = useCallback(() => {
    return setCurrentPeriodoUpload(null);
  }, []);

  const [currentDoc, setCurrentDoc] = useState(null);
  const unsetCurrentDoc = useCallback(() => {
    return setCurrentDoc(null);
  }, []);

  const refreshAfterUpload = useCallback(
    doc => {
      console.log("refreshAfterUpload", doc);
      setCurrentDoc(doc);
      refresh();
    },
    [refresh]
  );

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header p-3 bg-light shadow-sm">
          <div className="">
            <h4>Consegne documenti</h4>
            <div className="d-flex flex-row">
              <div className="row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm">
                  Data inizio
                </label>
                <div className="col-sm-8 py-2">
                  <DatePicker
                    isClearable={false}
                    selectsStart
                    startDate={filters.data_inizio}
                    endDate={filters.data_fine}
                    className="form-control form-control-sm col-sm-8"
                    autoComplete="off"
                    showYearDropdown
                    locale={"it"}
                    showMonthDropdown
                    scrollableYearDropdown
                    // dropdownMode="select"
                    yearDropdownItemNumber={80}
                    dateFormat="dd/MM/yyyy"
                    selected={filters.data_inizio}
                    onChange={(date, e) => {
                      return onFilterChange(date, "data_inizio");
                    }}
                    portalId="data-inizio-portal"
                  />
                </div>
              </div>

              <div className="row w-25 p-1">
                <label className="col-sm-4 col-form-label-sm">Data fine</label>
                <div className="col-sm-8 py-2">
                  <DatePicker
                    isClearable={false}
                    selectsEnd
                    startDate={filters.data_inizio}
                    endDate={filters.data_fine}
                    className="form-control form-control-sm col-sm-8"
                    autoComplete="off"
                    showYearDropdown
                    locale={"it"}
                    showMonthDropdown
                    scrollableYearDropdown
                    // dropdownMode="select"
                    yearDropdownItemNumber={80}
                    dateFormat="dd/MM/yyyy"
                    selected={filters.data_fine}
                    onChange={(date, e) => {
                      return onFilterChange(date, "data_fine");
                    }}
                    portalId="data-fine-portal"
                  />
                </div>
              </div>

              <div className="row w-25 p-1">
                <label className="col-sm-3 col-form-label-sm">Tipo dato</label>
                <TipoDatoSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(
                      v.length ? v : undefined,
                      "tipo_dato"
                    );
                  }}
                  currentFilter={filters.tipo_dato}
                ></TipoDatoSelector>
              </div>
              <div className="row w-25 p-1">
                <label className="col-sm-3 col-form-label-sm">
                  Scheda servizi
                </label>
                <SchedaSelector
                  className="col-sm-9"
                  multiple
                  onChange={values => {
                    const v = values.map(x => x.id);
                    return onFilterChange(v.length ? v : undefined, "scheda");
                  }}
                  currentFilter={filters.scheda}
                ></SchedaSelector>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {regoleConsegna ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Tipo dato</th>
                    <th>Scheda</th>
                    <th>Inizio</th>
                    <th>Fine</th>
                    <th>Documenti</th>
                    <th>Doc mancanti</th>
                  </tr>
                </thead>
                <tbody>
                  {regoleConsegna.map(regolaConsegna => (
                    <React.Fragment key={regolaConsegna.id}>
                      {regolaConsegna.documenti_periodo.map(
                        (documentoPeriodo, i) => (
                          <tr key={i}>
                            {i === 0 && (
                              <>
                                <td
                                  rowSpan={
                                    regolaConsegna.documenti_periodo.length
                                  }
                                >
                                  {regolaConsegna.tipo_dato_data.nome}
                                  <br />
                                  <small>
                                    {regolaConsegna.frequenza_attesa_periodo}{" "}
                                    {regolaConsegna.frequenza_attesa_numero && (
                                      <span>
                                        (
                                        {regolaConsegna.frequenza_attesa_numero}
                                        )
                                      </span>
                                    )}
                                  </small>
                                </td>
                                <td
                                  rowSpan={
                                    regolaConsegna.documenti_periodo.length
                                  }
                                >
                                  {regolaConsegna.scheda_data?.titolo}
                                </td>
                              </>
                            )}

                            <td>{documentoPeriodo.start_date}</td>
                            <td>{documentoPeriodo.end_date}</td>
                            <td>
                              <div>
                                {documentoPeriodo.docs.map(doc => (
                                  <div key={doc.id}>
                                    <div>
                                      {doc.titolo}
                                      {doc.bozza && <b> (bozza)</b>}{" "}
                                      <button
                                        className={`btn btn-sm ml-2 ${
                                          doc.bozza
                                            ? "btn-warning"
                                            : "btn-primary "
                                        }`}
                                        onClick={() => {
                                          setCurrentDoc(doc);
                                        }}
                                      >
                                        <MdCreate size="14" />
                                      </button>
                                    </div>
                                    <small>
                                      <DateFormat
                                        date={doc.data_modifica}
                                        format="DD/MM/YYYY HH:mm"
                                      ></DateFormat>
                                    </small>
                                  </div>
                                ))}
                              </div>
                              {(!regolaConsegna.frequenza_attesa_periodo ||
                                documentoPeriodo.docs.length <
                                  documentoPeriodo.expected) && (
                                <button
                                  className="btn btn-sm btn-primary"
                                  type="button"
                                  onClick={() => {
                                    setCurrentPeriodoUpload({
                                      periodo: documentoPeriodo,
                                      regolaConsegna
                                    });
                                  }}
                                >
                                  UPLOAD
                                </button>
                              )}
                            </td>
                            <td>
                              <span
                                className={`badge ${
                                  documentoPeriodo.missing > 0
                                    ? "badge-danger"
                                    : "badge-success"
                                }`}
                              >
                                {documentoPeriodo.missing}
                              </span>
                            </td>
                          </tr>
                        )
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            page={page}
            pagination={pagination}
            goToPage={goToPage}
          ></Paginator>
        </div>
      </Layout.Content>

      {currentPeriodoUpload && (
        <Modal
          isOpen={!!currentPeriodoUpload}
          toggle={unsetCurrentPeriodoUpload}
        >
          <DocumentiUploadForm
            periodo={currentPeriodoUpload.periodo}
            regolaConsegna={currentPeriodoUpload.regolaConsegna}
            uploadCallback={refreshAfterUpload}
            onClose={unsetCurrentPeriodoUpload}
          ></DocumentiUploadForm>
        </Modal>
      )}
      {currentDoc && (
        <Modal size="lg" isOpen={!!currentDoc} toggle={unsetCurrentDoc}>
          <DocumentoModal
            toggle={unsetCurrentDoc}
            idDocumento={currentDoc.id}
            refresh={refresh}
            showEditCommands
          ></DocumentoModal>
        </Modal>
      )}
    </Layout>
  );
};

export default DocumentiPeriodo;
