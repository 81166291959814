import React, { useCallback, useEffect, useState, useMemo } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Link } from "react-router-dom";
import Layout from "react-drawers";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/it";
import { CalendarListState } from "./localstate";
import { useRunRj, useRj } from "react-rocketjump";
import ServiziSelector from "../../components/ServiziSelector";
import VieSelector, { vieEncDec } from "../../components/VieSelector";
import qs from "query-string";

const localizer = momentLocalizer(moment);

const Eve = ({ event }) => {
  const [vie, setVie] = useRouterQueryParam("vie", [], vieEncDec);
  const vieParams = qs.stringify({
    vie: vieEncDec.encode(vie),
    data_fine: event.data,
    data_inizio: event.data
  });

  const url = `/navigatore-cartografico?${vieParams}`;
  const urlProg = `/programmazioni/${event.id}?${vieParams}`;

  return (
    <div>
      {event.id} - {event.servizio.codice_servizio} ({event.quantita}{" "}
      {event.servizio.scheda_data.um})<br />
      <Link to={url}>Mappa</Link> <Link to={urlProg}>Programmazioni</Link>
    </div>
  );
};

export default function Calendar({ history }) {
  const [startDate, setStartDate] = useRouterQueryParam(
    "data_inizio",
    moment()
      .startOf("month")
      .toDate(),
    {
      encode: date => moment(date).format("YYYY-MM-DD"),
      decode: dateParam => moment(dateParam).toDate()
    }
  );

  const [endDate, setEndDate] = useRouterQueryParam(
    "data_fine",
    moment()
      .endOf("month")
      .toDate(),
    {
      encode: date => moment(date).format("YYYY-MM-DD"),
      decode: dateParam => moment(dateParam).toDate()
    }
  );

  const [servizi, setServizi] = useRouterQueryParam("servizio", []);

  const [{ events }, { run: loadEvents }] = useRj(CalendarListState, []);

  const start_date = useMemo(() => moment(startDate).format("YYYY-MM-DD"), [
    startDate
  ]);
  const end_date = useMemo(() => moment(endDate).format("YYYY-MM-DD"), [
    endDate
  ]);
  const servizi_param = useMemo(() => {
    if (!servizi) {
      return "";
    }
    const serviziArr = Array.isArray(servizi) ? servizi : [servizi];
    return serviziArr.join(",");
  }, [servizi]);

  const [vie, setVie] = useRouterQueryParam("vie", [], vieEncDec);
  const vie_param = useMemo(() => {
    if (!vie) {
      return "";
    }
    const vieArr = Array.isArray(vie) ? vie : [vie];
    return vieArr.map(x => x.streetcode).join(",");
  }, [vie]);

  useEffect(() => {
    if (start_date && end_date) {
      loadEvents({
        start_date,
        end_date,
        servizio: servizi_param ? servizi_param.split(",") : undefined,
        vie: vie_param ? vie_param.split(",") : undefined
      });
    }
  }, [start_date, end_date, loadEvents, servizi_param, vie_param]);

  // const [
  //   { events, loading },
  //   { run: loadEvents }
  // ] = useRunRj(CalendarListState, [{start_date: startDate, end_date: endDate}]);

  return (
    <Layout>
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>
      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="d-flex justify-content-between sticky-header p-3 bg-light shadow-sm">
          <h4 className="mb-0">Calendario</h4>
          <div style={{ width: 200 }}>
            <VieSelector
              selected={vie}
              onChange={values => {
                setVie(
                  values.map(v => ({
                    streetcode: v.streetcode,
                    toponym: v.toponym
                  }))
                );
              }}
            ></VieSelector>
          </div>
          <div>
            <ServiziSelector
              selected={servizi}
              className="col-sm-10"
              onChange={values => {
                const v = values.map(x => x.id);
                setServizi(v);
              }}
            ></ServiziSelector>
          </div>
        </div>
        <div className="p-3">
          <BigCalendar
            culture="it-IT"
            components={{ event: Eve }}
            localizer={localizer}
            events={events || []}
            startAccessor={e => moment(e.start).toDate()}
            endAccessor={e => moment(e.end).toDate()}
            messages={{
              month: "Mese",
              day: "Giorno",
              week: "Settimana",
              today: "Oggi",
              previous: "Avanti",
              next: "Indietro"
            }}
            // onDoubleClickEvent={e => {
            //   const vieParams = qs.stringify({
            //     vie: vieEncDec.encode(vie),
            //     data_fine: e.data,
            //     data_inizio: e.data
            //   });
            //   const url = `/navigatore-cartografico?${vieParams}`;
            //   history.push(url);
            // }}
            // onSelectEvent={e => {
            //   const vieParams = qs.stringify({
            //     vie: vieEncDec.encode(vie),
            //     data_fine: e.data,
            //     data_inizio: e.data
            //   });
            //   const url = `/programmazioni/${e.id}?${vieParams}`;
            //   history.push(url);
            // }}
            // allDayAccessor={e => true}
            titleAccessor={e => {
              // const vieParams = qs.stringify({
              //   vie: vieEncDec.encode(vie),
              //   data_fine: e.data,
              //   data_inizio: e.data
              // });
              // const url = `/navigatore-cartografico?${vieParams}`;
              return (
                <span>
                  {e.id} - {e.servizio.codice_servizio} ({e.quantita}{" "}
                  {e.servizio.scheda_data.um})
                  {/* {' '}<Link to={url} onClick={e => e.preventDefault()}>Mappa</Link> */}
                </span>
              );
            }}
            style={{ height: 600 }}
            onRangeChange={range => {
              console.log("range change", range);
              if (Array.isArray(range)) {
                setStartDate(range[0]);
                setEndDate(range[1]);
              } else {
                setStartDate(range.start);
                setEndDate(range.end);
              }

              // loadStuff(range.start, range.end)
            }}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
}
