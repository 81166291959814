import React from "react";
import { useRunRj, deps, useRj } from "react-rocketjump";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col
} from "reactstrap";
import {
  transformErrorForForm,
  fixInitialValuesNull
} from "../../components/Fields/utils";
import { FileField, DateField, InputField } from "../../components/Fields";
import { CaricaDocumentoState } from "../../localstates/documento";
import GenericFormError from "../../components/Fields/GenericFormError";
import DateFormat from "../../components/DateFormat";
import moment from "moment";

const DocumentiUploadForm = ({
  periodo,
  regolaConsegna,
  onClose,
  uploadCallback
}) => {
  const { run: upload } = useRj(CaricaDocumentoState)[1];

  const constantData = {
    periodo_inizio: periodo.start_date,
    periodo_fine: periodo.end_date,
    regola_consegna: regolaConsegna.id,
    bozza: true
  };

  function getTitolo(regolaConsegna, periodo) {
    if (!regolaConsegna.frequenza_attesa_periodo) {
      return undefined;
    }
    const start = moment(periodo.start_date).format("DD/MM/YYYY");
    const end = moment(periodo.end_date).format("DD/MM/YYYY");
    return `${regolaConsegna.tipo_dato_data.nome} (${start} - ${end}) `;
  }

  return (
    <Formik
      initialValues={{ titolo: getTitolo(regolaConsegna, periodo) }}
      onSubmit={(values, actions) => {
        const uploadValues = {
          ...constantData,
          ...values
        };
        upload
          .onSuccess(resp => {
            console.log("success", resp);
            uploadCallback && uploadCallback(resp);
            onClose();
          })
          .onFailure(error => {
            actions.setErrors(transformErrorForForm(error));
            actions.setSubmitting(false);
          })
          .run(uploadValues);
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <ModalHeader className="flex-row justify-content-between bg-primary text-white">
            <span>Upload documento</span>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={6}>
                <b>Tipo dato:</b> {regolaConsegna.tipo_dato_data.nome}
              </Col>
              <Col sm={6}>
                <b>Frequenza:</b> {regolaConsegna.frequenza_attesa_periodo}
                {regolaConsegna.frequenza_attesa_numero && (
                  <span> ({regolaConsegna.frequenza_attesa_numero})</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <b>Scheda:</b>{" "}
                {regolaConsegna.scheda_data
                  ? regolaConsegna.scheda_data.titolo
                  : "-"}
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col sm={6}>
                <b>Inizio periodo</b>{" "}
                <DateFormat date={periodo.start_date}></DateFormat>
              </Col>
              <Col sm={6}>
                <b>Fine periodo</b>{" "}
                <DateFormat date={periodo.end_date}></DateFormat>
              </Col>
            </Row>
            <Row></Row>
            <hr />
            <Field
              required
              label={<b>Titolo</b>}
              component={InputField}
              name="titolo"
              readOnly={regolaConsegna.frequenza_attesa_periodo}
            ></Field>
            <Field
              required
              label={<b>Contenuto</b>}
              component={FileField}
              name="contenuto"
            ></Field>
            <Field
              component={InputField}
              label="Note"
              type="textarea"
              name="note"
            ></Field>
            <GenericFormError errors={errors}></GenericFormError>
          </ModalBody>
          <ModalFooter>
            {isSubmitting && <Spinner></Spinner>}
            {!isSubmitting && (
              <button className="btn btn-primary" type="submit">
                Carica
              </button>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentiUploadForm;
