import React, { useMemo } from "react";
import { useRunRj } from "react-rocketjump";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { SchedeServiziListState } from "./localstate";

import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

const SchedaSelector = ({
  onChange,
  className,
  multiple,
  clearButton,
  currentFilter
}) => {
  const [
    { schedeServizi, pagination, loading },
    { run: loadSchede }
  ] = useRunRj(SchedeServiziListState);

  const currentSelected = useMemo(() => {
    if (currentFilter && schedeServizi) {
      const selectedIds = Array.isArray(currentFilter)
        ? currentFilter
        : [currentFilter];
      return schedeServizi.filter(
        item => selectedIds.indexOf(item.id.toString()) !== -1
      );
    } else {
      return [];
    }
  }, [currentFilter, schedeServizi]);

  return (
    <Typeahead
      bodyContainer
      paginate
      placeholder="Ricerca scheda"
      id={"SchedaServiziSelector"}
      multiple={multiple}
      clearButton={clearButton}
      isLoading={loading}
      labelKey={option => `${option.titolo}`}
      onInputChange={text => {
        loadSchede({ search: text });
      }}
      options={schedeServizi || []}
      selected={currentSelected}
      onChange={onChange}
      className={className}
      size={"sm"}
    />
  );
};

export default SchedaSelector;
